import React from "react";
import {
  Typography,
  Box,
  Grid,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import QrCode2Icon from "@mui/icons-material/QrCode2";

import QRCode from "react-qr-code";
import { isTargetingApps } from "../helpers/Helpers";
import { useConfig } from "./../context";
import HelpToolTip from "./../components/common/HelpToolTip";

const generateQRCode = (
  variation: any,
  state: number,
  id: number,
  env: string,
  device: string = "ios",
  forceDatafile: boolean = false
) => {
  let scheme =
    env === "prod" ? "com.hm.goe://" : "com.accenture.HMMobileApp://";
  if (device === "android") {
    scheme = env === "prod" ? "hmgoe://" : "hmgoe://"; // Malski said same for test and prod
  }

  const params = new URLSearchParams();
  params.append("v", "1");
  if (forceDatafile) {
    params.append("action", "setDataFile");
  } else {
    params.append("exp", id.toString());
    params.append("var", variation.toString());
  }

  params.append("state", state === 30 ? "prod" : state === 20 ? "qa" : "dev");
  const url = new URL(scheme + "fabulous.applink.hm.com?" + params.toString());
  return url.toString();
};

const QrTooltip: React.FC<any> = ({ env, tp, vari, ruleData }) => {
  const styleLimitedWidthQrCode = {
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  const forceStateChange =
    (env === "prod" && tp === "ios" && ruleData.state < 30) ||
    (env === "test" && tp === "ios" && ruleData.state !== 20);

  return (
    <Tooltip
      classes={{ tooltip: "no-max-width" }}
      title={
        <Grid
          container
          maxWidth={forceStateChange ? 450 : 220}
          justifyContent={"space-between"}
          spacing={2}
        >
          {forceStateChange ? (
            <Grid maxWidth={200} container item xs={forceStateChange ? 6 : 12}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color={"textPrimary"}>
                  {forceStateChange ? "Scan first" : ""}
                </Typography>
                <QRCode
                  size={256}
                  style={{
                    height: "auto",
                    maxWidth: 200,
                    width: 200,
                  }}
                  value={generateQRCode(
                    vari.id,
                    ruleData.state,
                    ruleData.id,
                    env,
                    tp,
                    true
                  )}
                  viewBox={`0 0 256 256`}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color={"textPrimary"}
                  sx={styleLimitedWidthQrCode}
                >
                  Set datafile
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          <Grid container item xs={forceStateChange ? 6 : 12} maxWidth={200}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={"textPrimary"}>
                {forceStateChange ? "Scan second" : ""}
              </Typography>
              <QRCode
                size={256}
                style={{
                  height: "auto",
                  maxWidth: 200,
                  width: 200,
                }}
                value={generateQRCode(
                  vari.id,
                  ruleData.state,
                  ruleData.id,
                  env,
                  tp
                )}
                viewBox={`0 0 256 256`}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color={"textPrimary"}
                sx={styleLimitedWidthQrCode}
              >
                {vari.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      }
      componentsProps={{
        tooltip: {
          sx: {
            border: "1px solid black",
            bgcolor: "white",
            "& .MuiTooltip-arrow": {
              color: "black",
            },
          },
        },
      }}
    >
      <QrCode2Icon sx={{ fontSize: 50, ml: "-4px", mb: -1 }} />
    </Tooltip>
  );
};

const ExperimentMeta: React.FC<any> = ({ ruleData }) => {
  const { config } = useConfig();

  const theme = useTheme();

  const styleLimitedWidth = {
    maxWidth: "90%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <Grid container item xs={12}>
      {ruleData.id > 0 ? (
        <Grid item xs={12} md={3} sx={{}}>
          <Typography variant="h6">Experiment meta:</Typography>

          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2">Experiment-ID:</Typography>
            <Typography variant="subtitle2">{ruleData.id}</Typography>
          </Box>

          {ruleData.variations.map((e: any, index: number) => (
            <Box key={index} display="flex" justifyContent="space-between">
              <Typography sx={styleLimitedWidth} variant="body2">
                {e.name}:
              </Typography>
              <Typography variant="subtitle2">{e.id}</Typography>
            </Box>
          ))}
        </Grid>
      ) : null}
      {isTargetingApps(ruleData) &&
      ruleData.state <= 30 &&
      ruleData.state >= 10 ? (
        <Grid item xs={12} sx={{ marginLeft: -2, marginRight: -2 }}>
          <br />
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid item xs="auto">
                <Typography variant="h6">App links for QA</Typography>
              </Grid>
              <Grid item xs={2}>
                <HelpToolTip
                  text={config.cms_strings.experiments.qr_codes_info}
                  link={config.cms_strings.experiments.qr_codes_info_link}
                />
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <Grid item xs={12} container>
                <Grid item xs={12} container sx={{ pl: 2, pr: 2 }}>
                  <Grid item>
                    <Alert severity="info">
                      {config.cms_strings.experiments.qr_codes_tooltip}
                    </Alert>
                  </Grid>
                </Grid>

                <Grid item xs={12} container sx={{ p: 2 }}>
                  <Typography variant="h6">Production app:</Typography>

                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      <AppleIcon
                        sx={{
                          position: "relative",
                          fontSize: 32,
                          verticalAlign: "bottom",
                          mr: 1,
                          mb: "4px",
                        }}
                      />
                      iOS
                    </Typography>
                  </Grid>
                  {ruleData.variations.map((e: any, index: number) => (
                    <Grid item xs={2} key={index}>
                      <QrTooltip
                        env="prod"
                        tp="ios"
                        vari={e}
                        ruleData={ruleData}
                      />
                      <Typography variant="body2" sx={styleLimitedWidth}>
                        {e.name}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  sx={{
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? theme.palette.grey["800"]
                        : theme.palette.grey["200"],
                    p: 2,
                  }}
                >
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      <AndroidIcon
                        sx={{
                          position: "relative",
                          fontSize: 32,
                          verticalAlign: "bottom",
                          mr: 1,
                        }}
                      />
                      Android
                    </Typography>
                  </Grid>
                  {ruleData.variations.map((e: any, index: number) => (
                    <Grid item xs={2} key={index}>
                      <QrTooltip
                        env="prod"
                        tp="android"
                        vari={e}
                        ruleData={ruleData}
                      />
                      <Typography variant="body2" sx={styleLimitedWidth}>
                        {e.name}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12} container sx={{ p: 2 }}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Test app:</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      <AppleIcon
                        sx={{
                          position: "relative",
                          fontSize: 32,
                          verticalAlign: "bottom",
                          mr: 1,
                          mb: "4px",
                        }}
                      />
                      iOS
                    </Typography>
                  </Grid>
                  {ruleData.variations.map((e: any, index: number) => (
                    <Grid item xs={2} key={index}>
                      <QrTooltip
                        env="test"
                        tp="ios"
                        vari={e}
                        ruleData={ruleData}
                      />
                      <Typography variant="body2" sx={styleLimitedWidth}>
                        {e.name}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  sx={{
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? theme.palette.grey["800"]
                        : theme.palette.grey["200"],
                    p: 2,
                  }}
                >
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      <AndroidIcon
                        sx={{
                          position: "relative",
                          fontSize: 32,
                          verticalAlign: "bottom",
                          mr: 1,
                        }}
                      />
                      Android
                    </Typography>
                  </Grid>
                  {ruleData.variations.map((e: any, index: number) => (
                    <Grid item xs={2} key={index}>
                      <QrTooltip
                        env="test"
                        tp="android"
                        vari={e}
                        ruleData={ruleData}
                      />

                      <Typography variant="body2" sx={styleLimitedWidth}>
                        {e.name}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ExperimentMeta;
