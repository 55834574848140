import {
  createContext,
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { useFilters } from "./FilterContext";
import { FetchFlags } from "../../helpers/FetchData";
import { ApiResponse, FullFlag } from "../../types/Types";

interface FeatureContextType {
  data: ApiResponse | null;
  setData: (data: ApiResponse | null) => void;
  isLoading: boolean;
  handleSelectFlag: (flag_key: string) => void;
  selectedFlag: SelectedFlag | null;
  setSelectedFlag: (selectedFlag: SelectedFlag | null) => void;
  handleLoadFlag: (flagId: string) => void;
}

interface SelectedFlag {
  flagData: FullFlag;
}

export const FeatureContext = createContext<FeatureContextType | undefined>(
  undefined
);

export const FeatureProvider = (props: { children: React.ReactNode }) => {
  const [data, setData] = useState<ApiResponse | null>(null);
  const [selectedFlag, setSelectedFlag] = useState<SelectedFlag | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const { filters } = useFilters();

  const fetchAPI = useCallback(() => {
    setIsLoading(true);
    FetchFlags(
      (output: ApiResponse) => {
        setIsLoading(false);
        setData(output);
      },
      filters.page,
      filters.pageSize,
      filters.searchQuery,
      filters.team,
      filters.hasLiveRules,
      filters.brand,
      filters.archived
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    const debounceDelay = 400;
    let debounceTimeout: NodeJS.Timeout | null = null;
    if (filters.searchQuery) {
      debounceTimeout = setTimeout(() => {
        if (!isLoading) {
          fetchAPI();
        }
      }, debounceDelay);
    } else {
      if (!isLoading) {
        fetchAPI();
      }
    }

    return () => {
      if (debounceTimeout) clearTimeout(debounceTimeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleSelectFlag = useCallback(
    (flag_key: string) => {
      const selectedFlag = {
        flagData: data?.response.results.find(
          (flag: FullFlag) => flag?.flag_key === flag_key
        ),
      };

      setSelectedFlag(selectedFlag);
      window.history.pushState({}, "", `/features/${selectedFlag.flagData.id}`);
    },
    [data]
  );

  const handleLoadFlag = useCallback(
    (flagId: string) => {
      if (flagId === undefined) {
        setSelectedFlag(null);

        return;
      }
      const selectedFlag = {
        flagData: data?.response.results.find(
          (flag: FullFlag) => flag?.id?.toString() === flagId
        ),
      };

      setSelectedFlag(selectedFlag);
    },
    [data]
  );

  const value = useMemo(
    () => ({
      data,
      setData,
      isLoading,
      handleSelectFlag,
      selectedFlag,
      setSelectedFlag,
      handleLoadFlag,
    }),
    [data, isLoading, handleSelectFlag, selectedFlag, handleLoadFlag]
  );

  return (
    <FeatureContext.Provider value={value}>
      {props.children}
    </FeatureContext.Provider>
  );
};

export const useFeatures = () => {
  const context = useContext(FeatureContext);
  if (context === undefined) {
    throw new Error("useFeatures must be used within a FeatureProvider");
  }
  return context;
};
