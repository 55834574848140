import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Stack,
  Box,
  Grid,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { ApiResponse, FullFlag } from "../types/Types";
import { FetchFlag } from "../helpers/FetchData";
import Loader from "../components/common/Loader";
import ShowRules from "./ShowRules";
import { useConfig } from "./../context";
import ButtonBack from "../components/common/BackButton";
import CreateExperiment from "../CreateExperiment";

const Flag: React.FC<{}> = () => {
  const [loading, setLoading] = useState(true);
  const [flagData, setFlagData] = useState<FullFlag>({
    name: "",
    description: "",
    flag_key: "",
    state: 0,
    variables: [],
    region_key: "",
    team_id: 0,
    archived: 0,
  });

  const { flagId } = useParams();
  const { config } = useConfig();
  const [rulesLength, setrulesLength] = useState(99);
  const [selectRegion, setSelectRegion] = useState(
    localStorage.getItem("fabulousUserLatestRegion") || "0"
  );
  const [returnUrl, setReturnUrl] = useState("");

  const [searchParams] = useSearchParams();

  useEffect(() => {
    FetchFlag((output: ApiResponse) => {
      setFlagData(output.response[0]);
      setLoading(false);
    }, Number(flagId));
  }, [flagId]);

  useEffect(() => {
    setReturnUrl(`/features?${window.atob(searchParams.get("r") || "")}`);
  }, [searchParams]);

  const handleRegionChange = (event: any) => {
    const regionKeySelected = event.target.value;
    setSelectRegion(regionKeySelected);
    localStorage.setItem("fabulousUserLatestRegion", regionKeySelected);
  };

  const RegionSelector = () => {
    return (
      <FormControl
        sx={{ m: 1, minWidth: 155 }}
        className={selectRegion !== "0" ? "active-filter" : ""}
      >
        <InputLabel id="demo-simple-select-label">Region</InputLabel>
        <Select
          value={selectRegion}
          onChange={handleRegionChange}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Filter Region"
          size="small"
        >
          <MenuItem value="0">All</MenuItem>
          {config?.regions?.map(
            (item: { name: string; region_key: string }) => {
              return (
                <MenuItem key={item.name} value={item.region_key}>
                  {item.name}
                </MenuItem>
              );
            }
          )}
        </Select>
      </FormControl>
    );
  };

  const Content = () => {
    if (!flagData)
      return (
        <Alert sx={{ mb: 1 }} severity="warning">
          {config.cms_strings.features.feature_no_exists}
        </Alert>
      );

    return (
      <Box sx={{ p: 2 }}>
        {flagData?.archived === 1 ? (
          <Alert sx={{ mb: 1 }} severity="warning">
            {config.cms_strings.features.feature_archived}
          </Alert>
        ) : (
          <></>
        )}
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          sx={{ mb: 2 }}
        >
          <Grid item xs="auto" sx={{ marginRight: "auto" }}>
            <ButtonBack text="Features" link={returnUrl} />
          </Grid>
          <Grid item xs="auto">
            {flagData.team_id === 1 ? RegionSelector() : <></>}
          </Grid>
          <Grid item xs="auto">
            <CreateExperiment
              createDisabled={
                config.user.level < 25 ||
                (config.user.level === 25 && flagData.team_id !== 1) ||
                flagData?.archived === 1
              }
              prio={rulesLength + 10}
              flagData={flagData}
            />
          </Grid>
        </Grid>
        <Stack spacing={2}>
          <ShowRules
            flagId={Number(flagId)}
            flagData={flagData}
            setrulesLength={setrulesLength}
            selectRegion={selectRegion}
          />
        </Stack>
      </Box>
    );
  };

  if (loading || Object.keys(config).length === 0) {
    return <Loader />;
  } else {
    return Content();
  }
};

export default Flag;
