import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { FetchTimeline } from "../helpers/FetchData";
import { ApiResponse } from "../types/Types";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import { Grid, Card, Typography } from "@mui/material";
import TimeBoardFilters from "./TimeBoardFilters";
import { ApiTimeserie, DecisionPage } from "./Types";
import Loader from "../components/common/Loader";
import { Chart, ReactGoogleChartEvent } from "react-google-charts";
import TimeBoardTooltip from "./TimeBoardTooltip";
import ReactDOMServer from "react-dom/server";
import ColorPalette from "../helpers/ColorPalette";
import featureEnabled from "../helpers/featureEnabled";
import { useConfig } from "../context";

const TimeBoard: React.FC<any> = (props: any) => {
  const theme = useTheme();
  const [chartColors, setChartColors] = useState<any>([]);
  const [data, setData] = useState([]);
  const [apiData, setApiData] = useState<ApiTimeserie[]>([]);
  const [locale, setLocale] = useState("0");
  const [brand, setBrand] = useState(0);
  const [page, setPage] = useState(0);
  const [selectedPage, setSelectedPage] = useState<DecisionPage>({
    id: 0,
    name: "All",
    funnel_index: 0,
  });
  const [touchpoint, setTouchpoint] = useState("0");
  const [loading, setLoading] = useState(true);
  const { config } = useConfig();

  useEffect(() => {
    const currentPage = config.decision_pages.find(
      (i: DecisionPage) => i.id === page
    );
    if (currentPage) {
      setSelectedPage(currentPage);
    } else {
      setSelectedPage({
        id: 0,
        name: "All",
        funnel_index: 0,
      });
    }
  }, [page, config]);
  const navigate = useNavigate();
  (window as any).timseries = data;
  const clickExperiment = (data: any) => {
    const idArray = data.match(/id="([0-9]+)"/);
    if (idArray && idArray.length > 1) {
      const id = idArray[1];
      const item: any = apiData.find((item) => item.id === parseInt(id));
      navigate(`/features/${item.flag_id}/${item.id}`);
    }
  };
  const customTooltip = (item: any) => {
    return ReactDOMServer.renderToString(
      <TimeBoardTooltip item={item} mode={theme.palette.mode} />
    );
  };
  const processData = useCallback(
    (apiTimeseries: ApiTimeserie[]) => {
      const out: any = [
        [
          { type: "string", id: "Page" },
          { type: "string", id: "Name", p: { className: "watchtower-bar" } },
          { type: "string", role: "tooltip", p: { html: true } },
          { type: "date", id: "Start" },
          { type: "date", id: "End" },
        ],
      ];
      const sortingArray = [
        "Start",
        "Department page",
        "PLP",
        "PDP",
        "Shopping bag",
        "Checkout",
        "Other",
        "Multi-page",
      ];

      apiTimeseries.sort(
        (a: any, b: any) =>
          sortingArray.indexOf(a.decision_page) -
          sortingArray.indexOf(b.decision_page)
      );
      apiTimeseries.forEach((apiTimeserie) => {
        const planned_start = apiTimeserie.planned_start_date
          ? apiTimeserie.planned_start_date
          : moment().format();
        const planned_end = apiTimeserie.planned_end_date
          ? apiTimeserie.planned_end_date
          : apiTimeserie.start_time
          ? apiTimeserie?.start_time
          : moment().format();

        const start = planned_start.split("-");
        const end = planned_end.split("-");
        const page =
          apiTimeserie.meta && apiTimeserie.meta.decision_pages.length > 1
            ? "Multi-page"
            : apiTimeserie.decision_page;
        out.push([
          page,
          apiTimeserie.name,
          customTooltip(apiTimeserie),
          new Date(
            parseInt(start[0]),
            parseInt(start[1]) - 1,
            parseInt(start[2])
          ),
          new Date(parseInt(end[0]), parseInt(end[1]) - 1, parseInt(end[2])),
        ]);
      });

      out.sort(
        (a: any, b: any) =>
          sortingArray.indexOf(a[0]) - sortingArray.indexOf(b[0])
      );
      setData(out);
    },
    [theme.palette.mode] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    const palette = ColorPalette(apiData.length);
    const colors: any = [];
    apiData.forEach((item: any, idx: number) => {
      colors.push(palette(idx));
    });
    setChartColors(colors);
  }, [apiData]);

  useEffect(() => {
    setLoading(true);
    const params: any = {
      brand: brand,
      locale: locale,
      touchpoint: touchpoint,
    };
    if (featureEnabled("watchtower.multipages", config)) {
      params.pages = selectedPage;
    } else {
      params.page = page;
    }
    FetchTimeline((output: ApiResponse) => {
      if (output?.status !== 200) {
        enqueueSnackbar(output.message, { variant: "error" });
      } else {
        processData(output.response);
        setApiData(output.response);
        setLoading(false);
      }
    }, params);
  }, [processData, brand, locale, selectedPage, touchpoint, config, page]);

  const options = {
    tooltip: { isHtml: true },
    alternatingRowStyle: false,
    backgroundColor: theme.palette.background.default,
    enableInteractivity: true,
    colors: chartColors,
  };
  const chartEvents: ReactGoogleChartEvent[] = [
    {
      eventName: "select",
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper!.getChart();
        const selection = chart.getSelection();

        if (selection.length === 1) {
          const [selectedItem] = selection;
          const dataTable = chartWrapper!.getDataTable();
          const { row } = selectedItem;
          clickExperiment(dataTable?.getValue(row, 2));
        }
      },
    },
  ];
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TimeBoardFilters
          setLocale={setLocale}
          locale={locale}
          setBrand={setBrand}
          brand={brand}
          setPage={setPage}
          page={page}
          setTouchpoint={setTouchpoint}
          touchpoint={touchpoint}
        />
      </Grid>
      <Grid item xs={12} height="80vh">
        {loading ? (
          <Loader />
        ) : apiData.length === 0 ? (
          <Card sx={{ padding: 3 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs="auto">
                <Typography variant="h5" color="text.secondary">
                  There is no experiments planned for the selected filters.
                </Typography>
              </Grid>
            </Grid>
          </Card>
        ) : (
          <Chart
            className="watchtower-chart"
            chartType="Timeline"
            data={data}
            width="100%"
            height="100%"
            options={options}
            chartEvents={chartEvents}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TimeBoard;
