import {
  createContext,
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { useFilters } from "./FilterContext";
import { FetchExperiments } from "../../helpers/FetchData";
import { ApiResponse } from "../../types/Types";

interface ExperimentContextType {
  data: ApiResponse | null;
  setData: (data: ApiResponse | null) => void;
  isLoading: boolean;
}

export const ExperimentContext = createContext<
  ExperimentContextType | undefined
>(undefined);

export const ExperimentProvider = (props: { children: React.ReactNode }) => {
  const [data, setData] = useState<ApiResponse | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const { filters } = useFilters();

  const fetchAPI = useCallback(() => {
    setIsLoading(true);
    FetchExperiments(
      (output: ApiResponse) => {
        setIsLoading(false);
        setData(output);
      },
      filters.page,
      filters.pageSize,
      filters.searchQuery,
      filters.team,
      filters.state,
      filters.region,
      filters.liveDateRange,
      filters.brand,
      filters.type,
      filters.excludeRegions
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    const debounceDelay = 400;
    let debounceTimeout: NodeJS.Timeout | null = null;
    if (filters.searchQuery) {
      debounceTimeout = setTimeout(() => {
        if (!isLoading) {
          fetchAPI();
        }
      }, debounceDelay);
    } else {
      if (!isLoading) {
        fetchAPI();
      }
    }

    return () => {
      if (debounceTimeout) clearTimeout(debounceTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const value = useMemo(
    () => ({
      data,
      setData,
      isLoading,
    }),
    [data, isLoading]
  );

  return (
    <ExperimentContext.Provider value={value}>
      {props.children}
    </ExperimentContext.Provider>
  );
};

export const useExperiments = () => {
  const context = useContext(ExperimentContext);
  if (context === undefined) {
    throw new Error("useExperiments must be used within a ExperimentProvider");
  }
  return context;
};
