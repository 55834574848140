let debug: any;

if (process.env.NODE_ENV !== "production") {
  debug = console.log.bind(window.console);
} else {
  debug = function () {};
}

type Feature = {
  name: string;
  enabled: boolean;
  data: any;
};

const featureEnabled = (featureName: string, config: any) => {
  if (!config || !config.features) {
    debug("No features found in config");
    return false;
  }
  const feature = config.features.find((f: Feature) => f.name === featureName);
  let enabled = false;
  if (feature && feature.enabled) {
    enabled =
      feature.data && (feature.data.level || feature.data.permission)
        ? hasFeatureAccess(feature.data, config)
        : true;
  }
  debug(`Feature ${featureName} is ${enabled ? "enabled" : "disabled"}`);
  return enabled;
};

const hasFeatureAccess = (data: any, config: any) => {
  const hasLevelAccess = data.level && data.level <= config.user.level;
  //const hasPermissionAccess = data.permission && config.user.permissions.includes(p));
  debug(`User has ${hasLevelAccess ? "level" : "no"} access to feature`);
  return hasLevelAccess;
};

export default featureEnabled;
