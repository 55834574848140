import { useState, useEffect } from "react";

import {
  Grid,
  IconButton,
  Checkbox,
  Autocomplete,
  TextField,
  CircularProgress,
  createFilterOptions,
  Chip,
} from "@mui/material";
import ButtonBack from "../components/common/BackButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ColorizeIcon from "@mui/icons-material/Colorize";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSearchParams } from "react-router-dom";
export const Toolbar = (props: any) => {
  const {
    mockEnabled,
    handleToggleMock,
    segment,
    appliedSegment,
    handleSegmentChange,
    applySegment,
    experimentData,
    isLoadingResult,
  } = props;

  const [experimentLocales, setExperimentLocales] = useState<any[]>([]);
  const [experimentTouchpoints, setExperimentTouchpoints] = useState([]);
  const [enableApplySegment, setEnableApplySegment] = useState(false);
  const [returnUrl, setReturnUrl] = useState("");
  const [addedLocales, setAddedLocales] = useState<any[]>([]);
  const [showSegmentation, setShowSegmentation] = useState(false);

  const [searchParams] = useSearchParams();

  const getExperimentLocales = (data: any) => {
    const locales = data.attributes.reduce((locales: any, attr: any) => {
      if (attr.attribute === "locale") {
        attr.values.forEach((i: any) => locales.push(i));
      }
      return locales;
    }, []);
    setExperimentLocales(locales);
  };

  const getExperimentTouchpoints = (data: any) => {
    const tps = data.attributes.reduce((touchpoints: any, attr: any) => {
      if (attr.attribute === "touchpoint") {
        attr.values.forEach((i: any) => touchpoints.push(i));
      }
      return touchpoints;
    }, []);
    setExperimentTouchpoints(tps);
  };

  useEffect(() => {
    getExperimentLocales(experimentData);
    getExperimentTouchpoints(experimentData);
    setShowSegmentation(experimentData.type === "ab");
  }, [experimentData]);

  useEffect(() => {
    setEnableApplySegment(
      JSON.stringify(segment) !== JSON.stringify(appliedSegment)
    );
  }, [segment, appliedSegment]);

  useEffect(() => {
    setReturnUrl(`/results?${window.atob(searchParams.get("r") || "")}`);
  }, [searchParams]);

  const filter = createFilterOptions<any>();

  return (
    <Grid
      container
      justifyContent="flex-end"
      sx={{ mb: 3 }}
      alignItems="center"
      spacing={2}
    >
      <Grid item xs sx={{ marginRight: "auto", alignItems: "start" }}>
        <ButtonBack text="Results" link={returnUrl} />
        {process.env.REACT_APP_ENV !== "production" ? (
          <IconButton
            color={mockEnabled ? "error" : "default"}
            onClick={handleToggleMock}
          >
            <ColorizeIcon />
          </IconButton>
        ) : (
          <></>
        )}
      </Grid>
      {showSegmentation && (
        <>
          <Grid item>
            <Autocomplete
              className={
                appliedSegment.filter((i: any) => i.attribute === "locale")
                  .length > 0
                  ? "active-filter"
                  : ""
              }
              multiple
              ChipProps={{
                deleteIcon: <></>,
              }}
              fullWidth
              disableClearable
              id="segment-locales"
              options={experimentLocales}
              limitTags={1}
              size="small"
              defaultValue={[]}
              disableCloseOnSelect
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (
                  params.inputValue !== "" &&
                  !options.includes(params.inputValue)
                ) {
                  filtered.push(params.inputValue);
                }

                return filtered;
              }}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  style={{
                    fontSize: "0.8rem",
                    backgroundColor: experimentLocales.includes(option)
                      ? undefined
                      : "#b8e18f",
                  }}
                >
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    size="small"
                  />
                  {!experimentLocales.includes(option) ? "Add " : ""} {option}
                </li>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    deleteIcon={<></>}
                    label={option}
                    sx={{
                      backgroundColor: !addedLocales.includes(option)
                        ? undefined
                        : "#b8e18f",
                    }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label="Locales" placeholder="" />
              )}
              onChange={(_, newValue) => {
                const newLocales = newValue.filter(
                  (value) => !experimentLocales.includes(value)
                );
                if (newLocales.length > 0) {
                  setAddedLocales((prev) => [...prev, ...newLocales]);
                  setExperimentLocales((prev) => [...prev, ...newLocales]);
                }
                handleSegmentChange({ type: "locale", value: newValue });
              }}
              freeSolo
              sx={{
                "& .MuiAutocomplete-inputRoot": {
                  width: (theme) => theme.spacing(30),
                },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              className={
                appliedSegment.filter((i: any) => i.attribute === "touchpoint")
                  .length > 0
                  ? "active-filter"
                  : ""
              }
              multiple
              ChipProps={{ deleteIcon: <></> }}
              fullWidth
              disableClearable
              id="segment-touchpoints"
              options={experimentTouchpoints}
              limitTags={1}
              size="small"
              defaultValue={[]}
              disableCloseOnSelect
              ListboxProps={{ style: { maxHeight: 150 } }}
              renderOption={(props, option, { selected }) => (
                <li {...props} style={{ fontSize: "0.8rem" }}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    size="small"
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Touchpoints"
                  placeholder=""
                  style={{ fontSize: "0.8rem" }}
                />
              )}
              onChange={(e, data) =>
                handleSegmentChange({ type: "touchpoint", value: data })
              }
              sx={{
                "& .MuiAutocomplete-inputRoot": {
                  flexWrap: "nowrap!important",
                },
              }}
            />
          </Grid>
          <Grid item width="50px">
            <IconButton
              onClick={applySegment}
              disabled={!enableApplySegment}
              sx={{ height: "100%" }}
            >
              {isLoadingResult ? (
                <CircularProgress
                  size={16}
                  color="inherit"
                  sx={{ height: "100%", opacity: 0.5 }}
                />
              ) : (
                <FilterAltIcon
                  color={!enableApplySegment ? "disabled" : "action"}
                />
              )}
            </IconButton>
          </Grid>
        </>
      )}
    </Grid>
  );
};
