import React from "react";
import { useNavigate } from "react-router-dom";
import { DuplicateRule } from "../helpers/FetchData";
import {
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Box,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";
import DifferenceIcon from "@mui/icons-material/Difference";
import { useConfig } from "../context";

const DuplicateRuleButton: React.FC<any> = ({
  ruleId,
  flagId,
  type,
  disabled,
  meta,
}) => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const ruleType =
    type === "mab"
      ? "Multi-armed bandit"
      : type === "rollout"
      ? "Rollout"
      : "A/B-Test";
  const duplicateText = `Duplicate ${ruleType}`;

  const { config } = useConfig();
  const submitDuplicate = () => {
    if (disabled) return;

    confirm({
      title: `Duplicate ${ruleType}`,
      content: (
        <Box>
          <Typography variant="subtitle1">
            Are you sure you want to duplicate this {ruleType}?
          </Typography>
          {isOldRule() ? (
            <Typography variant="subtitle2" sx={{ fontStyle: "italic", mt: 2 }}>
              This {ruleType} is based on an older version of Fabulous and
              cannot be duplicated. Please create a new {ruleType}.
            </Typography>
          ) : (
            <></>
          )}
        </Box>
      ),
      confirmationButtonProps: {
        color: "primary",
        variant: "contained",
        disabled: isOldRule(),
      },
    })
      .then(() => {
        DuplicateRule((output: any) => {
          if (output?.status !== 200) {
            enqueueSnackbar(output.message, { variant: "error" });
          } else {
            enqueueSnackbar(`${ruleType} duplicated`, { variant: "success" });
            navigate(`/features/${flagId}/${output.response.insertId}`);
          }
        }, Number(ruleId));
      })
      .catch(() => {
        console.log("Cancelled");
      });
  };

  const isOldRule = () => {
    return !meta || !meta.version || meta.version < config.rule_version;
  };

  return (
    <ListItemButton
      disabled={disabled}
      onClick={submitDuplicate}
      sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "transparent" } }}
    >
      <ListItemIcon>
        <DifferenceIcon />
      </ListItemIcon>
      <ListItemText primary={duplicateText} />
    </ListItemButton>
  );
};

export default DuplicateRuleButton;
