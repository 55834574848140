import moment from "moment";

import { Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const TimeseriesChartTooltip = ({ active, payload, label }: any) => {
  const theme = useTheme();
  if (active && payload && payload.length) {
    return (
      <Paper
        sx={{
          height: "auto",
          position: "relative",
          p: 1,
        }}
        elevation={1}
      >
        <div
          style={{
            margin: "-4px -8px 0 -8px",
            borderRadius: "3px 3px 0 0",
            paddingLeft: "5px",
            borderBottom: `1px solid ${theme.palette.divider}`,
            display: "grid",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: "5px",
          }}
        >
          <Typography variant="subtitle2">
            {moment.unix(label / 1000).format("YYYY-MM-DD HH:mm")}
          </Typography>
        </div>
        {payload.map((item: any, index: any) => {
          return (
            <Typography
              variant="body2"
              key={item.name + label + index}
              style={{ color: item.color }}
              className="label"
            >
              {`${item.name}: ${parseFloat(item.value).toFixed(2)}`}
            </Typography>
          );
        })}
      </Paper>
    );
  }

  return null;
};
