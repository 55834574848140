import React, { useState } from "react";

import { Select, MenuItem, InputLabel, FormControl, Grid } from "@mui/material";

const BooleanFields: React.FC<any> = ({
  onChangeVariables,
  indexes,
  label,
  variable,
  disabled,
}) => {
  const [boolean, setBoolean] = useState<any>(variable.value);

  const handler = (event: any) => {
    const { value } = event.target;
    setBoolean(value);

    onChangeVariables(event, {
      ...indexes,
    });
  };

  const content = (
    <FormControl sx={{ minWidth: "100%" }} margin="dense">
      <InputLabel>{label}</InputLabel>
      <Select
        labelId="dropdown-select-label"
        value={boolean}
        onChange={handler}
        label={label}
        disabled={disabled}
      >
        {label === "Text color"
          ? [
              <MenuItem key="menu-item-red" value={"red"}>
                Red
              </MenuItem>,
              <MenuItem key="menu-item-black" value={"black"}>
                Black
              </MenuItem>,
            ]
          : [
              <MenuItem key="menu-item-true" value={"true"}>
                True
              </MenuItem>,
              <MenuItem key="menu-item-false" value={"false"}>
                False
              </MenuItem>,
            ]}
      </Select>
    </FormControl>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        {content}
      </Grid>
    </Grid>
  );
};

export default BooleanFields;
