import React from "react";
import { FilterProvider } from "./context/FilterContext";
import { FilterToolbar } from "./FilterToolbar";
import { ExperimentList } from "./ExperimentList";
import { ExperimentPagination } from "./ExperimentPagination";
import { ExperimentProvider } from "./context/ExperimentContext";

export const Results: React.FC = () => {
  return (
    <FilterProvider>
      <ExperimentProvider>
        <FilterToolbar />
        <ExperimentList />
        <ExperimentPagination />
      </ExperimentProvider>
    </FilterProvider>
  );
};

export default Results;
