import React from "react";
import { Typography, Paper, Grid, Box, Container, Button } from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import FastForwardIcon from "@mui/icons-material/FastForward";
import SpeedIcon from "@mui/icons-material/Speed";
import logo from "./assets/fabulous.png";
import CurrentLive from "./ProgramReporting/CurrentLive";
import { handleClickLearnMore } from "./helpers/Helpers";

const Start: React.FC<{}> = () => {
  return (
    <Container>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Grid container justifyContent="space-around" spacing={2}>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs="auto">
              <Typography variant="h6">Welcome to </Typography>
            </Grid>
            <Grid item xs="auto">
              <Box
                component="img"
                sx={{ height: 20, ml: 1, mr: 1 }}
                alt="Logo"
                src={logo}
              />
            </Grid>
            <Grid item xs="auto">
              <Typography variant="h6">
                also known as FEP (fABulous Experimentation Platform).
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="center" spacing={3}>
            <Grid item xs={8}>
              <Typography align="center" variant="subtitle2">
                Experimentation (A/B testing) is an extremely powerful
                capability with a multitude of user cases,
                <br /> enabling you to <strong>Discover</strong> customer &
                business value and enable you to <strong>Deliver</strong> it
                with confidence.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            spacing={2}
            alignContent="flex-start"
            sx={{ mb: 2 }}
          >
            <Grid item xs={3} container alignContent="flex-start" spacing={1}>
              <Grid item xs={1} sx={{ minWidth: 30 }}>
                <MonetizationOnIcon />
              </Grid>
              <Grid item xs>
                <Typography
                  display="block"
                  variant="h6"
                  sx={{ lineHeight: 1.2 }}
                >
                  Don't just talk about it, be outcome orientated
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ position: "relative", pb: "35px", minHeight: "230px" }}
              >
                <Typography variant="body2">
                  What if you could easily and confidently measure the impact of
                  your product?
                  <br />
                  <br />
                  Experimentation enables you to isolate the change(s) or
                  feature you're rolling out to our customers and establish the
                  actual impact, with confidence.
                  <br />
                  <br />{" "}
                </Typography>
                <Button
                  sx={{ position: "absolute", bottom: 0 }}
                  variant="outlined"
                  target="_blank"
                  onClick={handleClickLearnMore}
                  href="https://hennesandmauritz.sharepoint.com/sites/ExperimentationWebVisualAnalytics/SitePages/Validate-impact.aspx"
                >
                  Learn more
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={3} container alignContent="flex-start" spacing={1}>
              <Grid item xs={1} sx={{ minWidth: 30 }}>
                <FastForwardIcon />
              </Grid>
              <Grid item xs>
                <Typography
                  display="block"
                  variant="h6"
                  sx={{ lineHeight: 1.2 }}
                >
                  Mitigate bugs & errors through staged rollouts
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ position: "relative", pb: "35px", minHeight: "230px" }}
              >
                <Typography variant="body2">
                  What if you could ensure everything you roll out is bug and
                  error free?
                  <br />
                  <br />
                  Through staged rollouts, you get visibility on the impact of
                  the experience. If it's negatively affecting our customers &
                  business, you can roll it back in minutes.
                  <br />
                  <br />
                </Typography>
                <Button
                  sx={{ position: "absolute", bottom: 0 }}
                  variant="outlined"
                  target="_blank"
                  onClick={handleClickLearnMore}
                  href="https://hennesandmauritz.sharepoint.com/sites/ExperimentationWebVisualAnalytics/SitePages/User-Cases.aspx"
                >
                  Learn more
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={3} container alignContent="flex-start" spacing={1}>
              <Grid item xs={1} sx={{ minWidth: 30 }}>
                <SpeedIcon />
              </Grid>
              <Grid item xs>
                <Typography
                  display="block"
                  variant="h6"
                  sx={{ lineHeight: 1.2 }}
                >
                  Accelerate your ideas to market
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ position: "relative", pb: "35px", minHeight: "230px" }}
              >
                <Typography variant="body2">
                  What if you could always invest your time & resources in
                  winning ideas?
                  <br />
                  <br />
                  Time & resources are always in short supply, understanding
                  where you should invest your team's resources is as important
                  as knowing where you shouldn't.
                  <br />
                  <br />
                </Typography>
                <Button
                  sx={{ position: "absolute", bottom: 0 }}
                  variant="outlined"
                  target="_blank"
                  onClick={handleClickLearnMore}
                  href="https://hennesandmauritz.sharepoint.com/sites/ExperimentationWebVisualAnalytics/SitePages/Fail-fast.aspx"
                >
                  Learn more
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} justifyContent="center">
          <CurrentLive summarize={true} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Start;
