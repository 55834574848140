import sendEvent from "./sendEvent";

const interaction = (
  event: string,
  params?: Record<"name" | "stringValue", string>[]
) => {
  const eventParams = [
    { name: "page", stringValue: window.location.pathname },
    {
      name: "resolution",
      stringValue: window.innerWidth + "x" + window.innerHeight,
    },
  ];
  if (params) {
    params.forEach((param) => {
      eventParams.push(param);
    });
  }
  sendEvent(event, "interaction", eventParams);
};

export default interaction;
