import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Stack, Paper, LinearProgress } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import ErrorMessage from "../components/common/ErrorMessage";
import { useConfig } from "../context";
import { dashboardLink } from "../helpers/Helpers";
import { useFeatures } from "./context/FeatureContext";

const FeatureList: React.FC<{}> = () => {
  const { data, isLoading } = useFeatures();

  const { config } = useConfig();

  const getTeamName = (item: any) => {
    const team = config?.teams?.find((team: any) => team.id === item.team_id);
    const result = team ? team["name"] : "unknown";
    return result;
  };

  const getBrandName = (item: any) => {
    const brand = config?.brands?.find(
      (brand: any) => brand.id === item.brand_id
    );
    const result = brand ? brand["name"] : "unknown";
    return result;
  };

  const navigate = useNavigate();

  if (data && data?.status !== 200 && !isLoading) {
    return <ErrorMessage message={data?.message} />;
  } else {
    if (Object.keys(config).length === 0) return <></>; // Waiting for config

    return (
      <>
        <Grid
          sx={{
            width: "100%",
            height: "2px",
            marginBottom: "16px",
            textTransform: "uppercase",
          }}
        >
          <LinearProgress
            sx={{ visibility: isLoading ? "visible" : "hidden" }}
          />
          <Typography
            sx={{
              height: "14px",
              visibility: isLoading ? "hidden" : "",
            }}
            variant="caption"
          >
            {isLoading || data?.status !== 200 ? "..." : data?.response?.count}{" "}
            features
          </Typography>
        </Grid>
        <Box sx={{ pt: 2 }}>
          <Stack spacing={2}>
            {data?.response.results.map((item: any, key: any) => (
              <Paper
                onClick={(e) => {
                  dashboardLink(
                    e,
                    navigate,
                    `/features/${item.id}?r=${window.btoa(
                      window.location.search.replace("?", "")
                    )}`
                  );
                }}
                key={key}
                data-flagid={item.id}
                sx={{
                  pl: 2,
                  pr: 2,
                  pt: 1,
                  cursor: "pointer",
                  height: "80px",
                }}
                className={item.rules_in_prod ? "rule-is-live" : ""}
              >
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid xs={8}>
                    <Typography variant="button" display="block">
                      {item.name}
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      noWrap={true}
                    >
                      {item.description.slice(0, 100)}
                    </Typography>
                    <Typography
                      sx={{ fontStyle: "italic" }}
                      variant="caption"
                      display="block"
                      gutterBottom
                    >
                      Feature key: {item.flag_key}
                    </Typography>
                  </Grid>
                  <Grid xs maxHeight="75px" overflow="hidden">
                    <Typography variant="caption" display="block">
                      <strong>Brand:</strong> {getBrandName(item)}
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      lineHeight={1.1}
                    >
                      <strong>Team:</strong> {getTeamName(item)}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={2}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {item.rules_in_prod > 0 ? (
                      <Typography variant="caption" display="block">
                        {item.rules_in_prod > 1
                          ? item.rules_in_prod + " Live experiments"
                          : item.rules_in_prod + " Live experiment"}{" "}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Stack>
        </Box>
      </>
    );
  }
};

export default FeatureList;
