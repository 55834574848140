import React from "react";
import { FeatureProvider } from "./context/FeatureContext";
import { FilterToolbar } from "./FilterToolbar";
import FeatureList from "./FeatureList";
import { FeaturePagination } from "./FeaturePagination";
import { FilterProvider } from "./context/FilterContext";

const FeaturesContent: React.FC = () => {
  return (
    <>
      <FilterToolbar />
      <FeatureList />
      <FeaturePagination />
    </>
  );
};

export const Features: React.FC = () => {
  return (
    <FilterProvider>
      <FeatureProvider>
        <FeaturesContent />
      </FeatureProvider>
    </FilterProvider>
  );
};

export default Features;
