import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from "react";
import { useSearchParams } from "react-router-dom";

import {
  getDefaultBrand,
  getDefaultTeam,
  getDefaultRegion,
  getDefaultState,
  getDefaultType,
} from "../../helpers/Helpers";

export interface FilterState {
  page: number;
  searchQuery: string;
  team: number | { id: number; name: string; team_key: string };
  brand: number | 0;
  state: number | 0;
  type: string | null;
  region: string | null;
  excludeRegions: boolean;
  liveDateRange?: { start: string; end: string };
  startDate?: string;
  endDate?: string;
  debouncedSearch: string;
  pageSize: number;
  defaultType?: string;
  defaultRegion?: string;
  defaultBrand?: number;
  defaultState?: number;
  defaultTeam?: string;
}

interface FilterContextType {
  filters: FilterState;
  setFilters: (filters: Partial<FilterState>) => void;
  handleFilterChange: (filters: Partial<FilterState>) => void;
}

export const FilterContext = createContext<FilterContextType | undefined>(
  undefined
);

export const FilterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filtersState = {
    page: Number(searchParams.get("page")) || 1,
    searchQuery: searchParams.get("searchQuery") || "",
    team:
      Number(searchParams.get("team")) !== 0 &&
      Number(searchParams.get("team")) !== null
        ? Number(searchParams.get("team"))
        : getDefaultTeam(),
    brand:
      Number(searchParams.get("brand")) !== 0 &&
      Number(searchParams.get("brand")) !== null
        ? Number(searchParams.get("brand"))
        : getDefaultBrand(),
    state: Number(searchParams.get("state"))
      ? Number(searchParams.get("state"))
      : getDefaultState(),
    type:
      searchParams.get("type") !== null
        ? searchParams.get("type")
        : getDefaultType(),
    region:
      searchParams.get("region") !== null
        ? searchParams.get("region")
        : getDefaultRegion(),
    excludeRegions:
      searchParams.get("excludeRegions") === "true" ? true : false,
    liveDateRange:
      searchParams.get("liveDateRange") !== null
        ? JSON.parse(searchParams.get("liveDateRange") || "{}")
        : "",
    debouncedSearch: "",
    pageSize: Number(searchParams.get("pageSize")) || 25,
  };
  const [filters, setFiltersState] = useState<FilterState>(filtersState);
  useEffect(() => {
    const queryFilters: Partial<FilterState> = filtersState;

    setFiltersState((prev) => ({ ...prev, ...queryFilters }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const setFilters = useCallback((newFilters: Partial<FilterState>) => {
    setFiltersState((prev) => ({ ...prev, ...newFilters }));
  }, []);

  const handleFilterChange = useCallback(
    (newFilter: Partial<FilterState>) => {
      const updatedFilters = { ...filters, ...newFilter };
      setFilters(updatedFilters);
      const searchParamsObject: Record<string, string> = {};
      Object.keys(updatedFilters).forEach((key) => {
        if (key !== "liveDateRange") {
          searchParamsObject[key] = String(
            updatedFilters[key as keyof typeof updatedFilters]
          );
        }
      });
      searchParamsObject["liveDateRange"] = JSON.stringify(
        updatedFilters.liveDateRange
      );

      setSearchParams(searchParamsObject);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    [filters, setFilters, setSearchParams]
  );

  const value = useMemo(
    () => ({
      filters,
      setFilters,
      handleFilterChange,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, setFilters]
  );

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

export const useFilters = () => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error("useFilters must be used within a FilterProvider");
  }
  return context;
};
