import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import sendEvent from "./sendEvent";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

const PageView: React.FC<any> = () => {
  const { accounts, inProgress } = useMsal();
  let location = useLocation();

  const setPageParams = (page: string) => {
    const params = [
      { name: "page", stringValue: page },
      {
        name: "resolution",
        stringValue: window.innerWidth + "x" + window.innerHeight,
      },
    ];

    const pageParams = location.pathname.match(/\/([a-zA-Z]+)\/(\d+)\/?(\d*)/);

    if (pageParams !== null) {
      switch (pageParams[1]) {
        case "features":
          params.push({ name: "feature_id", stringValue: pageParams[2] });
          if (pageParams[3] !== "") {
            params.push({
              name: "feature_experiment_id",
              stringValue: pageParams[2],
            });
          }
          break;
        case "results":
          params.push({
            name: "result_experiment_id",
            stringValue: pageParams[2],
          });
          break;
        default:
          break;
      }
    }
    return params;
  };

  const isAuthenticated = useIsAuthenticated();
  const sendPageView = (page: string) => {
    sendEvent("page_view", "page", setPageParams(page));
  };

  useEffect(() => {
    if (isAuthenticated) {
      setPageParams(
        location.pathname === "/" ? "Start page" : location.pathname
      );
      sendPageView(
        location.pathname === "/" ? "Start page" : location.pathname
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, isAuthenticated]);

  useEffect(() => {
    if (inProgress !== InteractionStatus.None) return;

    if (!isAuthenticated && accounts.length === 0) {
      sendPageView("Login page");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress]);

  return <></>;
};

export default PageView;
