import React, { useState, useEffect } from "react";

import {
  Typography,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Autocomplete,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";
import moment from "moment";

import SearchField from "../components/common/Searchfield";
import { useConfig } from "../context";
import { RangePicker } from "../components/common/DateRangePicker";
import { useTitle } from "../helpers/useTitle";
import { interaction } from "../Tracking";
import { useFilters } from "./context/FilterContext";
import { useDebounce } from "../helpers/useDebounce";
import { useSearchParams } from "react-router-dom";

import {
  setDefaultBrand,
  setDefaultTeam,
  setDefaultRegion,
  setDefaultState,
  setDefaultType,
} from "../helpers/Helpers";

// TODO: Move to separate file when refactoring of types is done
interface Brand {
  id: number;
  name: string;
}

interface State {
  id: number;
  name: string;
  state_id: number;
}

export const FilterToolbar: React.FC<any> = () => {
  const { filters, handleFilterChange } = useFilters();
  const { searchQuery, team, brand, state, type, region, excludeRegions } =
    filters;

  const { config } = useConfig();
  const [searchParams] = useSearchParams();

  const onDateChange = (event: any) => {
    if (event.startDate && event.endDate) {
      const start = moment(event.startDate).format("YYYY-MM-DD HH:mm:ss");
      const end = moment(event.endDate)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      handleFilterChange({
        ...filters,
        page: 1,
        liveDateRange: { start: start, end: end },
      });
    } else {
      handleFilterChange({
        ...filters,
        page: 1,
        liveDateRange: { start: "", end: "" },
      });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFilterChange({
      ...filters,
      page: 1,
      searchQuery: event.target.value,
    });
  };

  const debouncedSearchQuery = useDebounce(searchQuery, 700);
  useEffect(() => {
    if (debouncedSearchQuery && debouncedSearchQuery.length > 2) {
      interaction("select_metric", [
        { name: "search_query", stringValue: debouncedSearchQuery },
      ]);
    }
  }, [debouncedSearchQuery]);

  const [managedTeams, setManagedTeams] = useState<
    { id: number; name: string; team_key: string }[]
  >([]);

  const [selectedTeam, setSelectedTeam] = useState({
    id: 0,
    name: "All",
    team_key: "all",
  });

  (window as any).selectedTeam = selectedTeam;

  useEffect(() => {
    if (config.teams) {
      const mergedTeams = [
        { id: 0, name: "All", team_key: "all" },
        ...config.teams,
      ];
      setManagedTeams(mergedTeams);
    }
  }, [config.teams]);

  useEffect(() => {
    if (team && typeof team === "number") {
      const foundTeam: any = managedTeams.find((i: any) => i.id === team);
      setSelectedTeam(foundTeam);
    } else {
      setSelectedTeam({ id: 0, name: "All", team_key: "all" });
    }
  }, [team, managedTeams]);

  const handleTypeChange = (event: any) => {
    handleFilterChange({
      ...filters,
      page: 1,
      type: event.target.value,
      defaultType: event.target.value,
    });
    setDefaultType(event.target.value);
    interaction("select_metric", [
      { name: "type", stringValue: event.target.value },
    ]);
  };

  const handleBrandChange = (event: any) => {
    handleFilterChange({
      ...filters,
      page: 1,
      brand: Number(event.target.value),
      defaultBrand: Number(event.target.value),
    });
    const brandName = (config.brands as Brand[]).find(
      (brand) => brand.id === event.target.value
    )?.name;
    setDefaultBrand(Number(event.target.value));
    interaction("select_metric", [
      { name: "brand", stringValue: brandName || "all" },
    ]);
  };

  const handleRegionChange = (event: any) => {
    handleFilterChange({
      ...filters,
      page: 1,
      region: event.target.value,
      defaultRegion: event.target.value,
    });
    setDefaultRegion(event.target.value);
    interaction("select_metric", [
      { name: "region", stringValue: event.target.value },
    ]);
  };

  const toggleExcludeRegions = (_: any) => {
    handleFilterChange({
      ...filters,
      page: 1,
      excludeRegions: !excludeRegions,
    });
    interaction("select_metric", [
      {
        name: "exclude_regions",
        stringValue: !excludeRegions ? "true" : "false",
      },
    ]);
  };

  const handleStateChange = (event: any) => {
    handleFilterChange({
      ...filters,
      page: 1,
      state: Number(event.target.value),
      defaultState: Number(event.target.value),
    });
    const stateName = (config.rule_states as State[]).find(
      (state) => state.state_id === event.target.value
    )?.name;
    setDefaultState(Number(event.target.value));
    interaction("select_metric", [
      { name: "state", stringValue: stateName || "all" },
    ]);
  };
  const handleTeamChange = (_: any, value: any) => {
    const id = value ? value.id : 0;
    handleFilterChange({
      ...filters,
      team: id,
      page: 1,
      defaultBrand: id,
    });
    setDefaultTeam(Number(id));
    interaction("select_metric", [
      { name: "team", stringValue: value?.name || "all" },
    ]);
  };

  const handleDateChange = (event: any) => {
    onDateChange(event);
    interaction("select_metric", [{ name: "date", stringValue: "selected" }]);
  };

  useTitle("Results");

  if (Object.keys(config).length === 0) return <></>;
  return (
    <Grid container justifyContent="flex-start">
      <Grid item md={config.user.level === 100 ? 2 : 3} xs={6}>
        <SearchField
          value={searchQuery}
          handleSearchChange={handleInputChange}
        />
      </Grid>
      {config.user.level === 100 ? (
        <Grid item md={1} xs={6} maxWidth={"80px!important"}>
          <FormControl
            sx={{
              display: config.user.level === 100 ? "inline-flex" : "none",
            }}
          >
            <FormControlLabel
              value="top"
              control={
                <Switch
                  checked={excludeRegions}
                  onChange={toggleExcludeRegions}
                  size="small"
                  color="success"
                  sx={{ mt: "-10px" }}
                />
              }
              label={
                <Typography sx={{ paddingBottom: 1 }} variant="caption">
                  No CXS
                </Typography>
              }
              labelPlacement="top"
            />
          </FormControl>
        </Grid>
      ) : (
        <> </>
      )}

      <Grid item md={1} xs={6}>
        <FormControl
          sx={{ m: 1, width: "98%" }}
          size="small"
          className={type !== "0" ? "active-filter" : ""}
        >
          <InputLabel id="type-select-label">Type</InputLabel>
          <Select
            value={type}
            onChange={handleTypeChange}
            labelId="type-select-label"
            id="type-select"
            label="Type"
          >
            <MenuItem value="0">All</MenuItem>
            <MenuItem value="ab">A/B</MenuItem>
            <MenuItem value="mab">MAB</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={2} xs={6}>
        <FormControl
          sx={{ m: 1, width: "98%" }}
          size="small"
          className={brand > 0 ? "active-filter" : ""}
        >
          <InputLabel id="brand-select-label">Brand</InputLabel>
          <Select
            value={config?.brands?.length > 0 ? brand : ""}
            onChange={handleBrandChange}
            labelId="brand-select-label"
            id="brand-select"
            label="Brand"
          >
            <MenuItem value="0">All</MenuItem>
            {config?.brands?.map((item: { name: string; id: number }) => {
              return (
                <MenuItem key={item.name} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item md={2} xs={3}>
        <Grid container spacing={0} direction="column">
          <Grid item xs={team === 1 ? 6 : 12}>
            <Autocomplete
              id="team-select"
              onChange={handleTeamChange}
              value={selectedTeam}
              disablePortal
              options={managedTeams}
              getOptionLabel={(option: any) => {
                const team = managedTeams.find((i: any) => i.id === option.id);
                return team ? team.name : "";
              }}
              sx={{ m: 1, width: "98%" }}
              size="small"
              className={
                typeof team === "number" && team > 0 ? "active-filter" : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Teams"
                  placeholder="All teams"
                />
              )}
              isOptionEqualToValue={(option, selectedValue) => {
                return option?.id === selectedValue?.id;
              }}
            />
          </Grid>

          {team !== 1 ? (
            <></>
          ) : (
            <Grid item xs={6}>
              <FormControl
                sx={{ m: 1, width: "98%" }}
                className={region !== "0" ? "active-filter" : ""}
                size="small"
              >
                <InputLabel id="region-label">Region</InputLabel>
                <Select
                  value={region}
                  onChange={handleRegionChange}
                  labelId="region-label"
                  id="region-select"
                  label="Region"
                >
                  <MenuItem value="0">All</MenuItem>

                  {config?.regions.map(
                    (item: { name: string; region_key: string }) => {
                      return (
                        <MenuItem key={item.name} value={item.region_key}>
                          {item.name}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item md={1} xs={3}>
        <FormControl
          sx={{ m: 1, width: "98%" }}
          size="small"
          className={state > -1 && state !== null ? "active-filter" : ""}
        >
          <InputLabel id="state-select-label">State</InputLabel>
          <Select
            onChange={handleStateChange}
            labelId="state-select-label"
            id="state-select"
            value={state}
            label="State"
          >
            <MenuItem value="-1">All</MenuItem>

            {config?.rule_states.map(
              (item: { name: string; id: number; state_id: number }) => {
                if (item.state_id >= 30) {
                  return (
                    <MenuItem key={item.name} value={item.state_id}>
                      {item.name}
                    </MenuItem>
                  );
                } else {
                  return null;
                }
              }
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={3} xs={6}>
        <RangePicker
          handleDateChange={handleDateChange}
          defaultValue={{
            startDate:
              searchParams.get("liveDateRange") !== null &&
              JSON.parse(searchParams.get("liveDateRange") || "{start:''}")
                .start,
            endDate:
              searchParams.get("liveDateRange") !== null &&
              JSON.parse(searchParams.get("liveDateRange") || "{end:''}").end,
          }}
        />
      </Grid>
    </Grid>
  );
};
