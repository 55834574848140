import React, { useState, useEffect } from "react";
import { useConfig } from "../../context";
import { FetchNotify } from "../../helpers/FetchData";
import { FepNotice } from "../../types/Types";

const Notifier: React.FC<{}> = () => {
  const pollerInterval = 30000;
  const [data, setData] = useState<FepNotice>({
    status: "",
    noticies: [],
  });
  const { setConfig } = useConfig();

  useEffect(() => {
    const interval = setInterval(() => {
      FetchNotify((output: any) => {
        if (output.response) {
          setData(output.response);
        }
      });
    }, pollerInterval);
    return () => {
      clearInterval(interval);
    };
  });

  // Set site_message
  useEffect(() => {
    if (data?.noticies?.length > 0) {
      const message = data.noticies.find((item) => item.key === "siteMessage");
      if (message) {
        setConfig((prevState) => {
          if (prevState.siteMessage !== message.value) {
            return { ...prevState, siteMessage: message.value };
          } else {
            return prevState;
          }
        });
      }
    }
  }, [data, setConfig]);
  return null;
};

export default Notifier;
