import React, { useEffect, useState, useRef, useCallback } from "react";
import { RetakeScreenshot, UploadScreenshotImages } from "../helpers/FetchData";
import { useConfig } from "../context";
import { enqueueSnackbar } from "notistack";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import {
  Stage,
  Layer,
  Group,
  Rect,
  Text as KonvaText,
  Image as KonvaImage,
} from "react-konva";
import useImage from "use-image";
import JSZip from "jszip";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";

interface Variation {
  id: number;
  name?: string;
}

interface ScreenshotGalleryProps {
  brandKey: string;
  experimentId: number;
  variations: Variation[];
  touchpoints: string[];
  state: number;
  screenshotUrl: string;
  hasScreenshots: boolean;
  ruleData: any;
  showAsIcon?: boolean;
}

interface GroupData {
  key: string;
  imageUrl: string;
  thumbUrl?: string;
  heading: string;
  x: number;
  y: number;
  groupWidth: number;
  groupHeight: number;
}

interface ScreenshotGroupProps {
  imageUrl: string;
  heading: string;
  x: number;
  y: number;
  padding: number;
}

const ScreenshotGroup: React.FC<ScreenshotGroupProps> = ({
  imageUrl,
  heading,
  x,
  y,
  padding,
}) => {
  const [image] = useImage(imageUrl);
  const textHeight = 70;
  const headingFont = "64px Arial";

  const measureText = (text: string, font: string) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      context.font = font;
      return context.measureText(text).width;
    }
    return 0;
  };

  const headingWidth = measureText(heading, headingFont);
  const imageWidth = image && image.width ? image.width : 0;
  const contentWidth = Math.max(headingWidth, imageWidth);
  const groupWidth = contentWidth;
  const imageHeight = image && image.height ? image.height : 0;
  const groupHeight = textHeight + imageHeight + padding * 2;

  return (
    <Group x={x} y={y}>
      <Rect
        x={-5}
        y={0}
        width={groupWidth + padding * 2}
        height={groupHeight}
        stroke="rgb(184,225,143)"
        strokeWidth={20}
      />
      <KonvaText
        text={heading}
        fontSize={64}
        fontFamily="Arial"
        x={padding}
        y={padding}
        fill="black"
      />
      {image && (
        <KonvaImage
          image={image}
          x={padding}
          y={padding + textHeight + padding}
        />
      )}
    </Group>
  );
};

const ScreenshotGallery: React.FC<ScreenshotGalleryProps> = ({
  brandKey,
  experimentId,
  variations,
  touchpoints,
  state,
  screenshotUrl,
  hasScreenshots,
  ruleData,
  showAsIcon = false,
}) => {
  const { config } = useConfig();
  const normalizedBrandKey = brandKey.toLowerCase();
  const [groupsData, setGroupsData] = useState<GroupData[]>([]);
  const [screenshotUrlControl, setScreenshotUrlControl] =
    useState(screenshotUrl);
  const [uploadedFiles, setUploadedFiles] = useState<{ [key: string]: File }>(
    {}
  );
  const [isUploading, setIsUploading] = useState(false);
  const stageRef = useRef<any>(null);
  const [stageScale, setStageScale] = useState(1);
  const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [retakeInProgress, setRetakeInProgress] = useState(false);
  const [open, setOpen] = useState(false);
  const [lookForScreenshots, setLookForScreenshots] = useState(hasScreenshots);
  // State to reset file inputs
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const modalWidth = window.innerWidth * 0.9;
  const modalHeight = window.innerHeight * 0.9;
  const controlPanelWidth = 300;
  const canvasWidth = modalWidth - controlPanelWidth;
  const canvasHeight = modalHeight;

  // URL validation state and functions (same as in GeneralFields)
  const [urlError, setUrlError] = useState("");
  const allowedDomains = [
    "hm.com",
    "weekday.com",
    "arket.com",
    "stories.com",
    "cos.com",
  ];

  const validateUrl = (value: string) => {
    if (!value) {
      setUrlError("");
      return;
    }
    try {
      const parsedUrl = new URL(value);
      const isValidDomain = allowedDomains.some((domain) =>
        parsedUrl.hostname.endsWith(domain)
      );
      if (!isValidDomain) {
        setUrlError(
          `The URL must be of the following domain: ${allowedDomains.join(
            ", "
          )}`
        );
      } else {
        setUrlError("");
      }
    } catch (error) {
      setUrlError("Please provide a valid URL.");
    }
  };

  const handleScreenshotUrlChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setScreenshotUrlControl(value);
    validateUrl(value);
  };

  useEffect(() => {
    setScreenshotUrlControl(screenshotUrl);
  }, [screenshotUrl]);

  useEffect(() => {
    setLookForScreenshots(hasScreenshots);
  }, [hasScreenshots]);

  const measureText = (text: string, font: string) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      context.font = font;
      return context.measureText(text).width;
    }
    return 0;
  };

  const loadImageDimensions = (
    url: string
  ): Promise<{ width: number; height: number }> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () =>
        resolve({ width: img.naturalWidth, height: img.naturalHeight });
      img.onerror = () => resolve({ width: 0, height: 0 });
      img.src = url;
    });
  };

  // Generate a unique key for each combination.
  const getGroupKey = (variation: Variation, touchpoint: string) =>
    `${variation.id}_${touchpoint}`;

  const handleFileUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    variationId: number,
    touchpoint: string
  ) => {
    const file = e.target.files ? e.target.files[0] : null;
    console.log(
      `File uploaded for Variation ${variationId} - ${touchpoint}:`,
      file
    );
    if (file) {
      setUploadedFiles((prev) => ({
        ...prev,
        [`${variationId}_${touchpoint}`]: file,
      }));
    }
  };

  // fetchAllGroups: used to load all images.
  const fetchAllGroups = useCallback(async (): Promise<{
    groups: GroupData[];
    totalContentWidth: number;
    maxGroupHeight: number;
  }> => {
    const baseImageUrl = process.env.REACT_APP_SCREENSHOT_IMG_BASE_URL;
    const spacing = 500;
    const padding = 20;
    const textHeight = 70;
    const headingFont = "64px Arial";
    let xOffset = 500;
    let maxGroupHeight = 0;
    const groups: GroupData[] = [];
    for (const touchpoint of touchpoints) {
      for (const variation of variations) {
        const key = getGroupKey(variation, touchpoint);
        const fullImageUrl = `${baseImageUrl}/${normalizedBrandKey}/${experimentId}_${
          variation.id
        }_${touchpoint}.jpg?t=${Date.now()}`;
        const thumbnailUrl = `${baseImageUrl}/${normalizedBrandKey}/${experimentId}_${
          variation.id
        }_${touchpoint}_thumb.jpg?t=${Date.now()}`;
        const heading =
          variation.id === 0
            ? `Control - ${touchpoint}`
            : `${
                variation.name || `Variation ${variation.id}`
              } - ${touchpoint}`;
        const headingWidth = measureText(heading, headingFont);
        const dims = await loadImageDimensions(fullImageUrl);
        if (dims.width === 0) continue;
        const groupContentWidth = Math.max(headingWidth, dims.width);
        const groupWidth = groupContentWidth;
        const groupHeight = textHeight + dims.height + padding * 2;
        groups.push({
          key,
          imageUrl: fullImageUrl,
          thumbUrl: thumbnailUrl,
          heading,
          x: xOffset,
          y: 0,
          groupWidth,
          groupHeight,
        });
        xOffset += groupWidth + spacing;
        if (groupHeight > maxGroupHeight) maxGroupHeight = groupHeight;
      }
    }
    return { groups, totalContentWidth: xOffset, maxGroupHeight };
  }, [touchpoints, variations, normalizedBrandKey, experimentId]);

  // fetchMissingGroups: attempts to load only images that haven't loaded yet.
  const fetchMissingGroups = useCallback(async (): Promise<{
    newGroups: GroupData[];
    totalContentWidth: number;
    maxGroupHeight: number;
  }> => {
    const baseImageUrl = process.env.REACT_APP_SCREENSHOT_IMG_BASE_URL;
    const spacing = 500;
    const padding = 20;
    const textHeight = 70;
    const headingFont = "64px Arial";
    let xOffset = 500;
    let maxGroupHeight = 0;
    const newGroups: GroupData[] = [];
    for (const touchpoint of touchpoints) {
      for (const variation of variations) {
        const key = getGroupKey(variation, touchpoint);
        const existing = groupsData.find((g) => g.key === key);
        if (existing) {
          xOffset += existing.groupWidth + spacing;
          if (existing.groupHeight > maxGroupHeight)
            maxGroupHeight = existing.groupHeight;
          continue;
        }
        const fullImageUrl = `${baseImageUrl}/${normalizedBrandKey}/${experimentId}_${
          variation.id
        }_${touchpoint}.jpg?t=${Date.now()}`;
        const thumbnailUrl = `${baseImageUrl}/${normalizedBrandKey}/${experimentId}_${
          variation.id
        }_${touchpoint}_thumb.jpg?t=${Date.now()}`;
        const heading =
          variation.id === 0
            ? `Control - ${touchpoint}`
            : `${
                variation.name || `Variation ${variation.id}`
              } - ${touchpoint}`;
        const headingWidth = measureText(heading, headingFont);
        const dims = await loadImageDimensions(fullImageUrl);
        if (dims.width === 0) continue;
        const groupContentWidth = Math.max(headingWidth, dims.width);
        const groupWidth = groupContentWidth;
        const groupHeight = textHeight + dims.height + padding * 2;
        newGroups.push({
          key,
          imageUrl: fullImageUrl,
          thumbUrl: thumbnailUrl,
          heading,
          x: xOffset,
          y: 0,
          groupWidth,
          groupHeight,
        });
        xOffset += groupWidth + spacing;
        if (groupHeight > maxGroupHeight) maxGroupHeight = groupHeight;
      }
    }
    return { newGroups, totalContentWidth: xOffset, maxGroupHeight };
  }, [touchpoints, variations, normalizedBrandKey, experimentId, groupsData]);

  // Initial load: fetch all groups.
  useEffect(() => {
    if (!lookForScreenshots) return;
    fetchAllGroups()
      .then(({ groups, totalContentWidth, maxGroupHeight }) => {
        setGroupsData(groups);
        const scaleX = canvasWidth / totalContentWidth;
        const scaleY = canvasHeight / maxGroupHeight;
        const initialScale = Math.min(scaleX, scaleY, 1);
        setStageScale(initialScale);
        const newStageX = (canvasWidth - totalContentWidth * initialScale) / 2;
        const newStageY = (canvasHeight - maxGroupHeight * initialScale) / 2;
        setStagePosition({ x: newStageX, y: newStageY });
      })
      .catch((err) => {
        console.error("Error computing groups", err);
      });
  }, [lookForScreenshots, canvasWidth, canvasHeight, fetchAllGroups]);

  // Periodic check: every 30 seconds, attempt to load only missing images.
  const expectedCount = touchpoints.length * variations.length;
  useEffect(() => {
    if (!lookForScreenshots) return;
    if (groupsData.length === expectedCount) return;
    const timer = setInterval(async () => {
      try {
        const { newGroups, totalContentWidth, maxGroupHeight } =
          await fetchMissingGroups();
        if (newGroups.length > 0) {
          const updatedGroups = [...groupsData, ...newGroups];
          setGroupsData(updatedGroups);
          const scaleX = canvasWidth / totalContentWidth;
          const scaleY = canvasHeight / maxGroupHeight;
          const initialScale = Math.min(scaleX, scaleY, 1);
          setStageScale(initialScale);
          const newStageX =
            (canvasWidth - totalContentWidth * initialScale) / 2;
          const newStageY = (canvasHeight - maxGroupHeight * initialScale) / 2;
          setStagePosition({ x: newStageX, y: newStageY });
          if (updatedGroups.length === expectedCount) {
            clearInterval(timer);
          }
        }
      } catch (err) {
        console.error("Error during periodic fetch", err);
      }
    }, 30000);
    return () => clearInterval(timer);
  }, [
    lookForScreenshots,
    groupsData,
    expectedCount,
    canvasWidth,
    canvasHeight,
    fetchMissingGroups,
  ]);

  // Standard mouse/drag/zoom handlers.
  const handleMouseDown = (e: any) => {
    setIsDragging(true);
    setDragStart({ x: e.evt.clientX, y: e.evt.clientY });
  };
  const handleMouseMove = (e: any) => {
    if (!isDragging) return;
    const dx = e.evt.clientX - dragStart.x;
    const dy = e.evt.clientY - dragStart.y;
    setStagePosition((prev) => ({ x: prev.x + dx, y: prev.y + dy }));
    setDragStart({ x: e.evt.clientX, y: e.evt.clientY });
  };
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleWheel = (e: any) => {
    if (!(e.evt.ctrlKey || e.evt.metaKey)) return;
    e.evt.preventDefault();
    const scaleBy = 1.05;
    const stage = stageRef.current;
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();
    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };
    const newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;
    setStageScale(newScale);
    setStagePosition({
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    });
  };

  // When retaking a screenshot, reload ALL images.
  const handleRetakeScreenshot = () => {
    setRetakeInProgress(true);
    RetakeScreenshot(
      (result: { status: number; response: any; message: string }) => {
        if (result.status !== 200) {
          enqueueSnackbar(result.message, { variant: "error" });
        } else {
          enqueueSnackbar("Screenshot retaken successfully", {
            variant: "success",
          });
          // Reload ALL images.
          fetchAllGroups().then(
            ({ groups, totalContentWidth, maxGroupHeight }) => {
              setGroupsData(groups);
              const scaleX = canvasWidth / totalContentWidth;
              const scaleY = canvasHeight / maxGroupHeight;
              const initialScale = Math.min(scaleX, scaleY, 1);
              setStageScale(initialScale);
              const newStageX =
                (canvasWidth - totalContentWidth * initialScale) / 2;
              const newStageY =
                (canvasHeight - maxGroupHeight * initialScale) / 2;
              setStagePosition({ x: newStageX, y: newStageY });
            }
          );
        }
        setRetakeInProgress(false);
      },
      experimentId,
      screenshotUrlControl,
      touchpoints,
      variations,
      ruleData.brand_id
    );
  };

  // When uploading images manually, reload ALL images and reset file inputs.
  const handleUploadImages = async () => {
    setIsUploading(true);
    try {
      for (const key in uploadedFiles) {
        const file = uploadedFiles[key];
        if (!file) continue;
        const [variationId, touchpoint] = key.split("_");
        const formData = new FormData();
        formData.append("file", file);
        formData.append("brand", String(ruleData.brand_id));
        formData.append("experimentId", String(experimentId));
        formData.append("variationId", variationId);
        formData.append("touchpoint", touchpoint);
        formData.append("brandKey", normalizedBrandKey);
        const result = await UploadScreenshotImages(formData);
        if (result.error) {
          throw new Error(result.error);
        }
        enqueueSnackbar(
          `File for variation ${variationId} and ${touchpoint} uploaded successfully.`,
          { variant: "success" }
        );
      }
      setUploadedFiles({});
      // Reset file inputs by updating the key.
      setFileInputKey(Date.now());
      // Reload ALL images.
      fetchAllGroups().then(({ groups, totalContentWidth, maxGroupHeight }) => {
        setGroupsData(groups);
        const scaleX = canvasWidth / totalContentWidth;
        const scaleY = canvasHeight / maxGroupHeight;
        const initialScale = Math.min(scaleX, scaleY, 1);
        setStageScale(initialScale);
        const newStageX = (canvasWidth - totalContentWidth * initialScale) / 2;
        const newStageY = (canvasHeight - maxGroupHeight * initialScale) / 2;
        setStagePosition({ x: newStageX, y: newStageY });
      });
    } catch (error: any) {
      enqueueSnackbar(`File upload error: ${error.message}`, {
        variant: "error",
      });
    } finally {
      setLookForScreenshots(true);
      setIsUploading(false);
    }
  };

  const downloadZip = async () => {
    if (!groupsData.length) return;
    const zip = new JSZip();
    let index = 0;
    for (const group of groupsData) {
      try {
        const response = await fetch(group.imageUrl);
        const blob = await response.blob();
        zip.file(`screenshot_${index}.jpg`, blob);
        index++;
      } catch (err) {
        console.error("Error fetching image for zip", err);
      }
    }
    const content = await zip.generateAsync({ type: "blob" });
    const downloadUrl = URL.createObjectURL(content);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = `fabulous_screenshots_experiment_${experimentId}.zip`;
    a.click();
    URL.revokeObjectURL(downloadUrl);
  };

  // Render thumbnails.
  const renderThumbnails = () =>
    hasScreenshots ? (
      <>
        <Typography align="left" variant="h6" sx={{ fontSize: "16px", mt: 2 }}>
          Screenshots
        </Typography>
        <Box
          sx={{
            position: "relative",
            width: "120px",
            height: "100px",
            cursor: "pointer",
            overflow: "hidden",
            borderRadius: "10px",
          }}
          onClick={() => setOpen(true)}
        >
          Loading...
          {groupsData.map((group, index) => (
            <img
              key={index}
              src={group.thumbUrl}
              alt={`Thumbnail ${index}`}
              style={{
                position: "absolute",
                top: `${index * 5}px`,
                left: `${index * 5}px`,
                width: "100px",
                borderRadius: "4px",
                border: "1px solid rgb(184,225,143)",
                boxShadow: "0px 2px 5px rgba(0,0,0,0.3)",
              }}
            />
          ))}
        </Box>
      </>
    ) : null;

  const renderTrigger = () => {
    return showAsIcon
      ? state === 30 && (
          <IconButton onClick={() => setOpen(true)}>
            <PhotoLibraryIcon />
          </IconButton>
        )
      : renderThumbnails();
  };

  return (
    <>
      {renderTrigger()}
      {open && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.8)",
            zIndex: 1300,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onMouseDown={(e) => {
            if (e.target === e.currentTarget) {
              setOpen(false);
            }
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: modalWidth,
              height: modalHeight,
              backgroundColor: "white",
              borderRadius: "8px",
              overflow: "hidden",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {/* Left pane: Canvas */}
            <Box sx={{ width: canvasWidth, position: "relative" }}>
              <Stage
                width={canvasWidth}
                height={canvasHeight}
                scaleX={stageScale}
                scaleY={stageScale}
                x={stagePosition.x}
                y={stagePosition.y}
                onWheel={handleWheel}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                ref={stageRef}
                style={{ background: "#f0f0f0" }}
              >
                <Layer>
                  {groupsData.length === 0 && lookForScreenshots && (
                    <KonvaText
                      text="It looks like the screenshots are being processed, wait or come back later...."
                      fontSize={24}
                      fontFamily="Arial"
                      fill="black"
                      x={0}
                      y={0}
                      width={400}
                      align="center"
                    />
                  )}
                  {groupsData.map((group) => {
                    const { key, ...groupProps } = group;
                    return (
                      <ScreenshotGroup key={key} {...groupProps} padding={20} />
                    );
                  })}
                </Layer>
              </Stage>

              <Box sx={{ position: "absolute", bottom: "20px", right: "20px" }}>
                <Button variant="contained" onClick={downloadZip}>
                  Download Screenshots
                </Button>
              </Box>
              {/* Navigation Instructions */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,

                  padding: "8px",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  Navigation Instructions
                </Typography>
                <Typography variant="body2">
                  <strong>Zoom (Mouse):</strong> Hold <strong>Ctrl</strong> (or{" "}
                  <strong>Cmd</strong> on Mac) and scroll.
                </Typography>
                <Typography variant="body2">
                  <strong>Zoom (Touchpad):</strong> Pinch with two fingers.
                </Typography>
                <Typography variant="body2">
                  <strong>Pan:</strong> Click and drag on the canvas.
                </Typography>
              </Box>
            </Box>

            {/* Right pane: Control Panel */}
            {config.user.level > 50 && (
              <Box
                sx={{
                  width: controlPanelWidth,
                  borderLeft: "1px solid #ccc",
                  padding: "16px",
                  overflowY: "auto",
                  backgroundColor: state !== 30 ? "lightgrey" : "inherit",
                  position: "relative",
                }}
              >
                <CloseIcon
                  onClick={() => setOpen(false)}
                  sx={{
                    position: "relative",
                    float: "right",
                    top: 0,
                    right: 0,
                    color: "black",
                    zIndex: 1400,
                    cursor: "pointer",
                    marginBottom: "16px",
                  }}
                />

                {(touchpoints.includes("desktop") ||
                  touchpoints.includes("mobile")) && (
                  <Box sx={{ marginBottom: "16px" }}>
                    <TextField
                      label="Screenshot URL"
                      value={screenshotUrlControl}
                      onChange={handleScreenshotUrlChange}
                      fullWidth
                      error={Boolean(urlError)}
                      helperText={urlError}
                      disabled={state !== 30}
                    />
                    <LoadingButton
                      endIcon={<AutorenewIcon />}
                      variant="contained"
                      onClick={handleRetakeScreenshot}
                      loadingPosition="end"
                      loading={isUploading || retakeInProgress}
                      disabled={state !== 30}
                      sx={{ marginTop: "8px" }}
                    >
                      <span>Retake Screenshot</span>
                    </LoadingButton>
                  </Box>
                )}
                {touchpoints.map((touchpoint) => (
                  <Box key={touchpoint} sx={{ marginBottom: "16px" }}>
                    <Typography
                      variant="h6"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {touchpoint} Uploads
                    </Typography>
                    {variations.map((variation) => (
                      <Box key={variation.id} sx={{ marginBottom: "8px" }}>
                        <Typography variant="body1">
                          {variation.id === 0
                            ? "Control"
                            : variation.name || `Variation ${variation.id}`}
                        </Typography>
                        <input
                          key={fileInputKey}
                          type="file"
                          accept="image/jpeg, image/png"
                          onChange={(e) =>
                            handleFileUpload(e, variation.id, touchpoint)
                          }
                          disabled={state !== 30}
                        />
                      </Box>
                    ))}
                  </Box>
                ))}
                <LoadingButton
                  endIcon={<CloudUploadIcon />}
                  variant="contained"
                  onClick={handleUploadImages}
                  loadingPosition="end"
                  loading={isUploading || retakeInProgress}
                  disabled={state !== 30}
                >
                  <span>Upload</span>
                </LoadingButton>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ScreenshotGallery;
