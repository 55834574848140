import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  Tooltip,
  Checkbox,
} from "@mui/material";

import { FetchExperimentDuration } from "./../helpers/FetchData";
import CenteredSpinner from "./../components/common/CenteredSpinner";
import CloseIcon from "@mui/icons-material/Close";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { ApiResponse } from "../types/Types";
import ExperimentDurationGraph from "./ExperimentDurationGraph";
import ColorPalette from "../helpers/ColorPalette";
import featureEnabled from "../helpers/featureEnabled";
import { useConfig } from "../context";

interface DurationData {
  metrics: {
    metricIndex: number;
    name: string;
    num_users_per_day: number;
    mde: number[];
    req_ss: number[];
    days_num: number[];
  }[];
  message: string | null;
}

const DurationVariation = (metric: any, key: any) => {
  if (!metric.days_num || metric.days_num!.length < 5) {
    return (
      <TableRow key={key}>
        <TableCell>{metric.name}</TableCell>
        <TableCell colSpan={5}>Insuffucient data</TableCell>
      </TableRow>
    );
  }
  return (
    <TableRow key={key}>
      <TableCell>{metric.name}</TableCell>
      {metric.days_num.map((day: number, index: number) => (
        <TableCell key={index}>{Intl.NumberFormat().format(day)}</TableCell>
      ))}
    </TableRow>
  );
};

const MdeData: React.FC<any> = ({ durationData, num_days }) => {
  return (
    <Card sx={{ mb: 2 }} variant="outlined">
      <TableContainer
        className="mde-table"
        sx={{
          "& .MuiTableCell-root": { fontSize: "0.675rem" },
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow sx={{ "& .MuiTableCell-root": { borderBottom: 0 } }}>
              <TableCell></TableCell>
              <TableCell sx={{ textAlign: "center" }} colSpan={5}>
                Number of days to reach:
              </TableCell>
            </TableRow>
            <TableRow sx={{ borderTop: 0 }}>
              <TableCell>Metric</TableCell>
              {num_days.map((_: number, index: number) => (
                <TableCell key={index}>{index + 1}%</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{ "& tr:last-of-type .MuiTableCell-root": { borderBottom: 0 } }}
          >
            {durationData.metrics.map((metric: any, key: any) => (
              <DurationVariation key={key} {...metric} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

const ExperimentDuration: React.FC<any> = ({ rule, isDisabled }) => {
  const { config } = useConfig();
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [durationData, setDurationData] = useState<DurationData>({
    message: "(This operation can take up to a minute.)",
    metrics: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [chartColors, setChartColors] = useState<any>([]);
  const [isWide, setisWide] = useState<any>(false);
  const [enhanceRequest, setEnhanceRequest] = useState(false);

  useEffect(() => {
    setDurationData({
      message: "(This operation can take up to a minute.)",
      metrics: [],
    });
    const successMetrics = rule.metrics.filter(
      (metric: any) => metric.is_success
    );
    setisWide(successMetrics.length > 1);
  }, [rule]);

  const getDuration = () => {
    setIsLoading(true);
    FetchExperimentDuration(
      (output: ApiResponse) => {
        if (output?.status !== 200) {
          setDurationData({
            message:
              "Duration couldn't be calculated, please check your experiment configuration",
            metrics: [],
          });
          setHasData(false);
          setIsLoading(false);
        } else if (
          output.response.metrics.length < 1 ||
          !output.response.metrics[0]?.days_num
        ) {
          setDurationData({
            message: "No data found",
            metrics: [],
          });
          setHasData(false);
          setIsLoading(false);
        } else {
          setDurationData(output.response);
          setHasData(true);
          const palette = ColorPalette(output.response.metrics.length);
          const colors: any = [];
          output.response.metrics.forEach((_: any, idx: number) => {
            colors[idx] = palette(idx);
          });
          setChartColors(colors);
        }
        setIsLoading(false);
      },
      rule.id,
      enhanceRequest
    );
  };

  const toggleOpen = () => {
    const hasValidLocale = rule.attributes.some(
      (e: any) => e.attribute === "locale"
    );
    const hasValidTouchpoint = rule.attributes.some(
      (e: any) => e.attribute === "touchpoint"
    );
    const hasSuccessMetric = rule.metrics.some((e: any) => e.is_success);

    if (hasValidLocale && hasValidTouchpoint && hasSuccessMetric) {
      setOpen(true);
    } else {
      setErrorOpen(true);
    }
  };

  return (
    rule.meta &&
    rule.meta.version >= 4 && (
      <>
        <Tooltip title="Estimate the number of days to reach statistical significance on your success metrics">
          <IconButton disabled={isDisabled} onClick={toggleOpen}>
            <HourglassEmptyIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          fullWidth={true}
          maxWidth={isWide ? "lg" : "md"}
        >
          <DialogTitle>
            <Grid
              container
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Grid item xs={12}>
                <Typography variant="h6">Experiment duration</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent
            sx={{
              pt: "10px!important",
            }}
          >
            <IconButton
              onClick={() => setOpen(false)}
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
            <Grid item xs={12} container spacing={1} alignItems="flex-start">
              <Grid item xs={12}>
                <Typography variant="body2">
                  {
                    config.cms_strings.experiments
                      .experiment_duration_description
                  }
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                container
                sx={{ pl: 1, pr: 2 }}
                alignItems="center"
                justifyContent="flex-end"
              >
                {featureEnabled(
                  "experiments.enhanced_duration_request",
                  config
                ) && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">Enhanced request</Typography>
                    <Checkbox
                      checked={enhanceRequest}
                      onChange={(e) => setEnhanceRequest(e.target.checked)}
                      disabled={isLoading}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    onClick={() => getDuration()}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              {isLoading && <CenteredSpinner sx={{ mb: 8 }} />}
              {!isLoading && !hasData ? (
                <Typography style={{ fontSize: "small", fontStyle: "italic" }}>
                  {durationData.message}
                </Typography>
              ) : (
                hasData &&
                !isLoading && (
                  <Grid container spacing={2}>
                    {durationData.metrics.map((metric: any, key: any) => (
                      <Grid item xs={isWide ? 6 : 12} key={key}>
                        <ExperimentDurationGraph
                          data={metric}
                          color={chartColors[key]}
                        />
                      </Grid>
                    ))}

                    <Grid item xs={12}>
                      <MdeData
                        durationData={durationData}
                        num_days={durationData.metrics[0].days_num}
                      />
                    </Grid>
                  </Grid>
                )
              )}
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog open={errorOpen} onClose={() => setErrorOpen(false)}>
          <DialogContent sx={{ p: "30px" }}>
            <IconButton
              onClick={() => setErrorOpen(false)}
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="body2">
              Your experiment must have the following to calculate the duration:
            </Typography>
            <ul>
              <li>
                <Typography variant="body2">
                  At least one audience with the locale attribute
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  At least one audience with the touchpoint attribute
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  At least one success metric
                </Typography>
              </li>
            </ul>
          </DialogContent>
        </Dialog>
      </>
    )
  );
};

export default ExperimentDuration;
