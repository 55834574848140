import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Grid,
} from "@mui/material";
import { CreateFlag } from "../helpers/FetchData";
import { ApiResponse } from "../types/Types";
import { useConfig } from "./../context";
import { enqueueSnackbar } from "notistack";
import { interaction } from "../Tracking";

const NewFlag: React.FC<{}> = () => {
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({
    name: "",
    flag_key: "",
    description: "",
    team_id: 0,
    brand_id: 0,
  });
  const [errorState, setErrorState] = useState(true);
  const [loading, setLoading] = useState(false);
  const [keyTouched, setKeyTouched] = useState(false);

  const { config } = useConfig();
  const navigate = useNavigate();

  const handleClickOpen = () => {
    interaction("new_feature");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitForm = () => {
    setLoading(true);

    interaction("create_feature");
    CreateFlag((output: ApiResponse) => {
      setLoading(false);
      if (
        output.status === 409 &&
        output.response.hasOwnProperty("code") &&
        output.response.code === "ER_DUP_ENTRY"
      ) {
        enqueueSnackbar(
          "A feature with that key already exists, please use a different key.",
          { variant: "error" }
        );
      } else {
        handleClose();
        navigate(`/features/${output.response.insertId}`);
        enqueueSnackbar("Feature created", { variant: "success" });
      }
    }, formState);
  };

  const onChangeHandler = (e: any) => {
    const { name, value } = e.target;

    if (name === "name" && !keyTouched) {
      const keyVal = value.replace(/ /g, "_");
      setFormState((prevState) => ({ ...prevState, name: value }));
      setFormState((prevState) => ({ ...prevState, flag_key: keyVal }));
    } else if (name === "flag_key") {
      setFormState((prevState) => ({ ...prevState, key: value }));
      setKeyTouched(true);
    }

    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    setErrorState(
      formState.name.length < 3 ||
        formState.flag_key.length < 3 ||
        formState.description.length < 3 ||
        formState.team_id === 0
    );
  }, [formState]);

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        style={{ float: "right" }}
      >
        New feature
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={submitForm}>
          <DialogTitle>Create feature</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the feature info, all fields are mandatory.
            </DialogContentText>

            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label="Feature name"
              type="text"
              fullWidth
              onChange={onChangeHandler}
            />
            <TextField
              margin="dense"
              id="flag_key"
              name="flag_key"
              label="Feature key"
              type="text"
              fullWidth
              value={formState.flag_key}
              onChange={onChangeHandler}
            />
            <TextField
              margin="dense"
              id="description"
              name="description"
              label="Feature description"
              type="text"
              fullWidth
              onChange={onChangeHandler}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl sx={{ mt: 2, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label">Team</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="team_id"
                    label="Team"
                    onChange={onChangeHandler}
                    defaultValue={0}
                  >
                    <MenuItem value={0}>Choose your team</MenuItem>
                    {config.teams?.map((team: any) => (
                      <MenuItem value={team.id} key={team.id}>
                        {team.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ mt: 2, minWidth: 120 }}>
                  <InputLabel id="brand-select-label">Brand</InputLabel>
                  <Select
                    labelId="brand-select-label"
                    id="brand-select"
                    name="brand_id"
                    label="Team"
                    onChange={onChangeHandler}
                    defaultValue={0}
                  >
                    <MenuItem value={0}>Choose brand</MenuItem>
                    {config.brands?.map((brand: any) => (
                      <MenuItem value={brand.id} key={brand.id}>
                        {brand.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              disabled={errorState}
              variant="contained"
              onClick={submitForm}
            >
              {loading ? <CircularProgress /> : "Create"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default NewFlag;
