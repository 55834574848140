import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Grid,
  IconButton,
} from "@mui/material";
import { useConfig } from "../context";
import { FetchLocales } from "../helpers/FetchData";
import CancelIcon from "@mui/icons-material/Cancel";
import { interaction } from "../Tracking";

const TimeBoardFilters: React.FC<any> = (props: any) => {
  const { config } = useConfig();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 500,
        width: 250,
        marginLeft: 50,
      },
    },
  };

  const [localesList, setLocalesList] = useState([]);
  useEffect(() => {
    FetchLocales((output: any) => {
      if (output?.status !== 200) {
        console.log(output.message);
      } else {
        setLocalesList(output.response);
      }
    });
  }, []);
  interface Brand {
    id: number;
    name: string;
  }

  const handleBrandChange = (event: any) => {
    props.setBrand(Number(event.target.value));
    const brandName = (config.brands as Brand[]).find(
      (brand) => brand.id === event.target.value
    )?.name;

    interaction("select_metric_watchtower", [
      { name: "brand", stringValue: brandName || "all" },
    ]);
  };
  const handleTouchpointChange = (event: any) => {
    props.setTouchpoint(event.target.value);
    interaction("select_metric_watchtower", [
      { name: "touchpoint", stringValue: event.target.value },
    ]);
  };
  const handleLocaleChange = (event: any) => {
    props.setLocale(event.target.value);
    interaction("select_metric_watchtower", [
      { name: "locale", stringValue: event.target.value },
    ]);
  };
  const handlePageChange = (event: any) => {
    props.setPage(Number(event.target.value));
    interaction("select_metric_watchtower", [
      { name: "page", stringValue: event.target.value.toString() },
    ]);
  };
  const clearFilters = () => {
    props.setBrand(0);
    props.setTouchpoint("0");
    props.setLocale("0");
    props.setPage(0);
    interaction("select_metric_watchtower", [
      { name: "brand", stringValue: "all" },
      { name: "touchpoint", stringValue: "all" },
      { name: "locale", stringValue: "all" },
      { name: "page", stringValue: "all" },
    ]);
  };

  if (Object.keys(config).length === 0) return <></>;
  return (
    <Grid container justifyContent="flex-start">
      <Grid item md={2} xs={6}>
        <FormControl
          sx={{ m: 1, width: "98%" }}
          size="small"
          className={props.brand > 0 ? "active-filter" : ""}
        >
          <InputLabel id="brand-select-label">Brand</InputLabel>
          <Select
            value={config?.brands?.length > 0 ? props.brand : ""}
            onChange={handleBrandChange}
            labelId="brand-select-label"
            id="brand-select"
            label="Brand"
            MenuProps={MenuProps}
          >
            <MenuItem value="0">All</MenuItem>
            {config?.brands?.map((item: { name: string; id: number }) => {
              return (
                <MenuItem key={item.name} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item md={2} xs={6}>
        <FormControl
          sx={{ m: 1, width: "98%" }}
          size="small"
          className={props.touchpoint > 0 ? "active-filter" : ""}
        >
          <InputLabel id="touchpoint-select-label">Touchpoint</InputLabel>
          <Select
            defaultValue={"0"}
            value={props.touchpoint}
            onChange={handleTouchpointChange}
            labelId="touchpoint-select-label"
            id="touchpoint-select"
            label="Touchpoint"
            MenuProps={MenuProps}
          >
            <MenuItem value="0">All</MenuItem>
            {config?.touchpoints?.map((item: { name: string; id: number }) => {
              return (
                <MenuItem key={item.name} value={item.name}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item md={2} xs={6}>
        <FormControl
          sx={{ m: 1, width: "98%" }}
          size="small"
          className={props.locale !== "0" ? "active-filter" : ""}
        >
          <InputLabel id="locale-select-label">Locale</InputLabel>
          <Select
            defaultValue={"0"}
            value={props.locale}
            onChange={handleLocaleChange}
            labelId="locale-select-label"
            id="locale-select"
            label="Locale"
            MenuProps={MenuProps}
            disabled={props.brand === 0}
          >
            <MenuItem value="0">All</MenuItem>
            {localesList
              .filter(
                (locale: { name: string; id: number; brand_id: number }) =>
                  locale.brand_id === props.brand
              )
              .map((item: { name: string; id: number }) => {
                return (
                  <MenuItem key={item.name} value={item.name}>
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={2} xs={6}>
        <FormControl
          sx={{ m: 1, width: "98%" }}
          size="small"
          className={props.page > 0 ? "active-filter" : ""}
        >
          <InputLabel id="page-select-label">Page</InputLabel>
          <Select
            defaultValue={0}
            value={props.page}
            onChange={handlePageChange}
            labelId="page-select-label"
            id="page-select"
            label="Page"
            MenuProps={MenuProps}
          >
            <MenuItem value="0">All</MenuItem>
            {config?.decision_pages?.map(
              (item: { name: string; id: number }) => {
                return (
                  <MenuItem key={item.name} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              }
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={2} xs={6}>
        <IconButton
          onClick={clearFilters}
          sx={{ height: "100%", ml: 2, opacity: 0.5 }}
        >
          <CancelIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default TimeBoardFilters;
