import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  Typography,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Switch,
  FormControl,
  FormControlLabel,
} from "@mui/material";

import CreateIcon from "@mui/icons-material/Create";
import {
  AdminFetchToggles,
  AdminUpdateToggle,
  AdminCreateToggle,
} from "../helpers/FetchData";
import CenteredSpinner from "../components/common/CenteredSpinner";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const Toggles: React.FC<{}> = () => {
  interface Toggle {
    id: number;
    name: string;
    enabled: boolean;
    data: object | null | string;
    archived: boolean;
  }

  const [toggles, setToggles] = React.useState<Toggle[]>([]);
  const [editOpen, setEditOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [selectedToggle, setSelectedToggle] = React.useState<Toggle>({
    id: 0,
    name: "",
    enabled: false,
    data: null,
    archived: false,
  });

  useEffect(() => {
    AdminFetchToggles((output: any) => {
      setToggles(output.response);
    });
  }, []);

  const handleEdit = (id: number) => {
    const toggle = toggles.find((toggle: any) => toggle.id === id);
    if (toggle) {
      setSelectedToggle({ ...toggle, data: JSON.stringify(toggle.data) });
      setEditOpen(true);
    }
  };
  const handleCreate = () => {
    setSelectedToggle({
      id: 0,
      name: "",
      enabled: false,
      data: "{}",
      archived: false,
    });
    setEditOpen(true);
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setSelectedToggle((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateCallback = (output: any, error: any) => {
    if (output.status === 200) {
      setSaving(false);
      setEditOpen(false);
      setToggles([]);
      AdminFetchToggles((output: any) => {
        setToggles(output.response);
      });
    }
  };
  const handleSave = () => {
    setSaving(true);
    if (selectedToggle.id === 0) {
      AdminCreateToggle((output: any, error: any) => {
        updateCallback(output, error);
      }, selectedToggle);
    } else {
      AdminUpdateToggle((output: any, error: any) => {
        updateCallback(output, error);
      }, selectedToggle);
    }
  };

  return (
    <Container>
      <Paper sx={{ p: 2 }}>
        <Grid container justifyContent="space-around">
          <Grid item xs={8}>
            <Typography variant="h5">Toggles</Typography>
          </Grid>
          <Grid item xs={4} container justifyContent="flex-end">
            <Button
              variant="contained"
              disabled={saving}
              onClick={handleCreate}
            >
              Create
            </Button>
          </Grid>
          <Grid item xs={12} container>
            {toggles.length === 0 ? (
              <Grid item xs={12}>
                <CenteredSpinner />
              </Grid>
            ) : (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Enabled</TableCell>
                      <TableCell>Restrictions</TableCell>
                      <TableCell>Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {toggles.map((toggle: any) => (
                      <TableRow key={toggle.id}>
                        <TableCell>{toggle.name}</TableCell>
                        <TableCell>
                          {toggle.enabled ? (
                            <CheckBoxIcon color="action" />
                          ) : (
                            <CheckBoxOutlineBlankIcon color="action" />
                          )}
                        </TableCell>
                        <TableCell>
                          {Object.keys(toggle.data).length ? (
                            <CheckBoxIcon color="action" />
                          ) : (
                            <CheckBoxOutlineBlankIcon color="action" />
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            sx={{ color: "rgba(0,0,0,1)!important" }}
                            onClick={() => handleEdit(toggle.id)}
                          >
                            <CreateIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={editOpen}
        fullWidth={true}
        maxWidth="sm"
        onClose={() => setEditOpen(false)}
      >
        <DialogTitle>Edit toggle</DialogTitle>

        <DialogContent sx={{ pt: "20px!important" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                label="Name"
                name="name"
                value={selectedToggle.name}
                onChange={handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <FormControlLabel
                  value="top"
                  control={
                    <Switch
                      checked={selectedToggle.enabled ? true : false}
                      onChange={(e) =>
                        setSelectedToggle((prevState) => ({
                          ...prevState,
                          enabled: !prevState.enabled,
                        }))
                      }
                      color="success"
                    />
                  }
                  label={<Typography variant="caption">Enabled</Typography>}
                  labelPlacement="top"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                label="Data"
                name="data"
                value={selectedToggle.data}
                onChange={handleChange}
                multiline
                rows={4}
              ></TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleSave} disabled={saving}>
            Save
          </Button>{" "}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Toggles;
