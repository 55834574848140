import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Tooltip,
  LinearProgress,
} from "@mui/material";
import moment from "moment";

import { useConfig } from "../context";
import { useExperiments } from "./context/ExperimentContext";
import { ExperimentItem } from "../types/Types";
import ErrorMessage from "../components/common/ErrorMessage";
import { dashboardLink } from "../helpers/Helpers";

interface State {
  name: string;
  state_id: number;
}

interface Team {
  name: string;
  id: number;
  team_key: string;
}

interface Region {
  name: string;
  id: number;
  region_key: string | null;
}

interface Brand {
  id: number;
  name: string;
}

export const ExperimentList: React.FC = () => {
  const { config } = useConfig();
  const { data, isLoading } = useExperiments();
  const navigate = useNavigate();

  function getStateName(state_id: number): State | undefined {
    const states = config.rule_states as State[];
    const state = states.find((s) => s.state_id === state_id);
    return state;
  }

  function getTeamName(id: number): Team | undefined {
    const teams = config.teams as Team[];
    const team = teams.find((s) => s.id === id);
    return team;
  }

  function getRegionName(region_key: string | null): Region | undefined {
    if (region_key === null) {
      return undefined;
    }
    const regions = config.regions as Region[];
    const region = regions.find((s) => s.region_key === region_key);
    return region;
  }

  const getBrandName = (brandId: number): string => {
    const brand = config?.brands?.find((brand: Brand) => brand.id === brandId);
    const result = brand ? brand["name"] : "unknown";
    return result;
  };

  if (data && data?.status !== 200 && !isLoading) {
    return <ErrorMessage message={data?.message} />;
  } else {
    if (Object.keys(config).length === 0) return <></>;

    return (
      <Grid container justifyContent="center">
        <Grid sx={{ width: "100%", height: "2px", marginBottom: "16px" }}>
          <LinearProgress
            sx={{ visibility: isLoading ? "visible" : "hidden" }}
          />

          <Typography
            sx={{
              height: "14px",
              visibility: isLoading ? "hidden" : "",
            }}
            variant="caption"
          >
            {isLoading || data?.status !== 200 ? "..." : data?.response?.count}{" "}
            experiments
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table
              sx={{
                minWidth: 650,
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell width={"50%"}>Name</TableCell>
                  <TableCell align="right">State</TableCell>
                  <TableCell align="right">Modified</TableCell>
                  <TableCell align="right">Region</TableCell>
                  <TableCell align="right">Team</TableCell>
                  <TableCell align="right">Brand</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.response.results.map((item: ExperimentItem) => (
                  <TableRow
                    className="experiment-list-row"
                    onClick={(e) => {
                      const cellText = document.getSelection();

                      if (cellText?.type !== "Range") {
                        dashboardLink(
                          e,
                          navigate,
                          `/results/${item.id}?s=${new Date(
                            item.start_time
                          ).getTime()}&r=${window.btoa(
                            window.location.search.replace("?", "")
                          )}`
                        );
                      }
                    }}
                    key={item.id}
                  >
                    <TableCell component="th" scope="row">
                      <Typography>{item.name}</Typography>
                      <Tooltip
                        placement="left"
                        enterDelay={800}
                        title={item.hypothesis ? item.hypothesis : ""}
                      >
                        <Typography
                          className="experiment-list-overflow-text"
                          fontSize={12}
                          color="rgb(110 110 110 / 87%)"
                        >
                          {item.hypothesis}
                        </Typography>
                      </Tooltip>
                    </TableCell>

                    <TableCell align="right">
                      <span
                        style={
                          item.state === 30
                            ? {
                                cursor: "auto",
                                fontWeight: "bold",
                                color: "rgba(127, 189, 49)",
                              }
                            : {}
                        }
                      >
                        {getStateName(item.state)?.name}
                      </span>
                    </TableCell>

                    <TableCell align="right">
                      <Tooltip
                        title={
                          item.updated_at
                            ? `Last modified at: ${moment(
                                item.updated_at
                              ).format("MMM D HH:mm, YYYY")}`
                            : ""
                        }
                      >
                        <span style={{ cursor: "auto" }}>
                          {moment(item.updated_at).format("MMM D, YYYY")}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title={getRegionName(item.region_key)?.name}>
                        <span
                          style={{
                            cursor: "auto",
                            textTransform: "uppercase",
                          }}
                        >
                          {item.region_key ?? ""}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip
                        title={
                          (
                            config.teams.find(
                              (team: { id: number; name: string }) =>
                                team.id === item.team_id
                            ) || { name: "" }
                          ).name
                        }
                      >
                        <span
                          style={{
                            cursor: "auto",
                            textTransform: "uppercase",
                          }}
                        >
                          {getTeamName(item.team_id)?.team_key}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">
                      {getBrandName(item.brand_id) ?? ""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
};
