import React from "react";
import { Pagination } from "@mui/material";

import { useFilters } from "./context/FilterContext";
import { useFeatures } from "./context/FeatureContext";

export const FeaturePagination: React.FC = () => {
  const { filters, handleFilterChange } = useFilters();
  const { data } = useFeatures();

  if (!data) {
    return null;
  }

  return (
    <Pagination
      sx={{
        marginTop: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      count={Math.ceil(data?.response.count / filters.pageSize)}
      page={filters.page}
      onChange={(_, value) =>
        handleFilterChange({
          ...filters,
          page: Number(value),
        })
      }
    />
  );
};
