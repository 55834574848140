import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  Stack,
  Paper,
  Typography,
  Tooltip,
  Chip,
  Box,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  InputLabel,
  Switch,
} from "@mui/material";
import { FetchRules, UpdatePriorities } from "../helpers/FetchData";
import { useConfig } from "./../context";
import ChangeState from "../FlagViewRule/ChangeState";
import { enqueueSnackbar } from "notistack";
import CreateIcon from "@mui/icons-material/Create";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Loader from "../components/common/Loader";

import MoreMenu from "../FlagViewRule/MoreMenu";
import ScheduleIcon from "@mui/icons-material/Schedule";
import moment from "moment";
import { useTitle } from "../helpers/useTitle";
import { formatAudienceMatchType } from "../helpers/Helpers";
import { dashboardLink } from "../helpers/Helpers";

const ShowRules: React.FC<{
  flagId: number;
  flagData: any;
  setrulesLength: any;
  selectRegion: any;
}> = (props) => {
  const { flagId, flagData, setrulesLength, selectRegion } = props;
  const { config } = useConfig();
  const navigate = useNavigate();
  const [rulesData, setRulesData] = useState([]);
  const [viewRulesData, setViewRulesData] = useState([]);
  const [rulesLoaded, setRulesLoaded] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const location = useLocation();
  const [selectedState, setSelectedState] = useState(-1);
  const [showDeleted, setShowDeleted] = useState(false);

  const getRules = useCallback(() => {
    setRulesLoaded(false);
    const regionKey =
      flagData.team_id === 1
        ? (selectRegion === "0" ? "" : selectRegion) || ""
        : "";
    FetchRules(
      (output: any) => {
        setRulesData(output.response);
        setRulesLoaded(true);
        setrulesLength(output.response.length);
      },
      flagId,
      regionKey,
      showDeleted
    );
  }, [flagData.team_id, flagId, selectRegion, setrulesLength, showDeleted]);

  useEffect(() => {
    getRules();
  }, [getRules, refresh]);

  useEffect(() => {
    setRefresh(location.state?.refresh || false);
  }, [location.state?.refresh]);

  useEffect(() => {
    setViewRulesData(
      selectedState >= 0
        ? rulesData.filter((item: any) => item.state === selectedState)
        : rulesData
    );
  }, [rulesData, selectedState]);

  const getRegionName = (regionKey: any) => {
    const region = config?.regions?.find(
      (region: any) => region.region_key === regionKey
    );
    const result = region ? region["name"] : false;
    return result;
  };

  const changeStateCallback = (id: number, state: any) => {
    const newRules = rulesData.filter((e: any) => {
      if (e.id === id) {
        e.state = state;
      }
      return e;
    });

    if (state === 50) {
      newRules.splice(
        newRules.findIndex((e: any) => e.id === id),
        1
      );
    }
    setRulesData(newRules);
  };

  const handleOnSortEnd = (oldIndex: number, newIndex: number) => {
    const newRules = arrayMoveImmutable(rulesData, oldIndex, newIndex);
    setRulesData(newRules);

    const priorities = newRules.map((each: any, index: number) => {
      if (each.type === "rollout") {
        index = index + 1000;
      }
      return {
        prio: index,
        ruleId: each.id,
      };
    });

    const obj = {
      priorities,
    };

    UpdatePriorities(
      (output: any) => {
        if (output?.status !== 200) {
          enqueueSnackbar(output.message, { variant: "error" });
        } else {
          const message = "Priority order has been updated";
          enqueueSnackbar(message, { variant: "success" });
        }
      },
      Number(flagId),
      obj
    );
  };

  const dragEnabled = (regionKey: string) => {
    return !(
      selectedState >= 0 ||
      config.user.level < 25 ||
      (config.user.region.length > 0 && config.user.region !== regionKey) ||
      (flagData.team_id === 1 && Number(selectRegion) === 0)
    );
  };

  const DragSpace = React.forwardRef<HTMLDivElement>((props, ref) => {
    return (
      <div ref={ref} className={"drag-space "}>
        <DragIndicatorIcon />
      </div>
    );
  });

  const getTeamName = (item: any) => {
    const team = config?.teams?.find((team: any) => team.id === item.team_id);
    const result = team ? team["name"] : "unknown";
    return result;
  };

  const getBrandName = (item: any) => {
    const brand = config?.brands?.find(
      (brand: any) => brand.id === item.brand_id
    );
    const result = brand ? brand["name"] : "unknown";
    return result;
  };

  const toggleDeleted = () => {
    setShowDeleted(!showDeleted);
  };
  const StateSelector = () => {
    return (
      <>
        <FormControl
          sx={{
            display: config.user.level === 100 ? "inline-flex" : "none",
          }}
        >
          <FormControlLabel
            value="top"
            control={
              <Switch
                checked={showDeleted}
                onChange={toggleDeleted}
                size="small"
                color="success"
                sx={{ mt: "-10px" }}
              />
            }
            label={
              <Typography sx={{ paddingBottom: 1 }} variant="caption">
                Deleted
              </Typography>
            }
            labelPlacement="top"
          />
        </FormControl>
        <FormControl
          sx={{ m: 1, minWidth: 130 }}
          className={selectedState >= 0 ? "active-filter" : ""}
        >
          <InputLabel id="selectedtState_label">Filter state</InputLabel>
          <Select
            value={selectedState}
            labelId="selectedtState_label"
            id="selectedtState"
            label="Filter State"
            size="small"
            onChange={(event: any) => setSelectedState(event.target.value)}
          >
            <MenuItem value="-1">All</MenuItem>
            {config?.rule_states
              ?.filter((i: any) => i.state_id < 50)
              .map((item: { name: string; state_id: number }) => {
                return (
                  <MenuItem key={item.state_id} value={item.state_id}>
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </>
    );
  };
  const DisplayFlagInfo: React.FC<any> = () => {
    const { name, description, flag_key } = flagData;

    useTitle(name);

    return (
      <Stack spacing={2}>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid xs={12} item container>
              <Grid item xs>
                <Typography variant="h5">{name}</Typography>
              </Grid>
              <Grid item>
                <Link to={`/features/${flagId}/settings`}>
                  <CreateIcon color="primary" />
                </Link>
              </Grid>
            </Grid>
            <Grid xs={12} item container justifyContent="space-between">
              <Grid item xs={9}>
                <Typography variant="body1">{description}</Typography>
                <Typography variant="caption">
                  Key: <em style={{ fontWeight: "bold" }}>{flag_key}</em>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="caption" display="block">
                  <strong>Brand:</strong> {getBrandName(flagData)}
                </Typography>
                <Typography variant="caption" display="block" lineHeight={1.1}>
                  <strong>Team:</strong> {getTeamName(flagData)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Stack>
    );
  };

  const showHoverAudiences = (audiences: []) => {
    const reduced = audiences.slice(3);
    return reduced.map((item: any, index: number) => {
      return (
        <Box key={index}>
          <Typography variant="caption" display="block" noWrap={true}>
            {item.name} ({item.attribute}{" "}
            {formatAudienceMatchType(item.matchType)} {item.values.join(", ")})
          </Typography>
        </Box>
      );
    });
  };

  const showSchedule = (schedule: any) => {
    let tooltip = "";
    const start = schedule.find((i: any) => i.action === "start");
    const stop = schedule.find((i: any) => i.action === "stop");

    tooltip = start
      ? `Start: ${moment(start?.action_time).format("YYYY-MM-DD HH:mm")}`
      : "";
    tooltip += start && stop ? "\n " : "";
    tooltip += stop
      ? `Stop: ${moment(stop?.action_time).format("YYYY-MM-DD HH:mm")}`
      : "";
    return (
      <Tooltip title={<div style={{ whiteSpace: "pre-line" }}>{tooltip}</div>}>
        <ScheduleIcon color="action" fontSize="small" />
      </Tooltip>
    );
  };

  const showAudiencesList = (audiences: []) => {
    return audiences.map((item: any, index: number) => {
      if (index < 3) {
        return (
          <Grid
            item
            xs
            key={index}
            sx={{ maxWidth: "80px!important", flexGrow: "0!important" }}
          >
            <Tooltip
              key={index}
              placement="top"
              enterDelay={200}
              title={`${item.name} (${item.attribute} ${formatAudienceMatchType(
                item.matchType
              )} ${item.values.join(", ")})`}
            >
              <Chip label={item.name} size="small" sx={{ mr: 1 }} />
            </Tooltip>
          </Grid>
        );
      } else if (index === 3) {
        return (
          <Grid
            item
            xs
            key={index}
            sx={{ maxWidth: "50px!important", flexGrow: "0!important" }}
          >
            <Tooltip
              key={index}
              placement="top"
              enterDelay={200}
              title={
                <React.Fragment>{showHoverAudiences(audiences)}</React.Fragment>
              }
            >
              <Chip
                label={"+" + audiences.slice(3).length}
                size="small"
                sx={{ mr: 1 }}
              />
            </Tooltip>
          </Grid>
        );
      } else {
        return null;
      }
    });
  };

  const ShowRulesData: React.FC<any> = () => {
    return (
      <Stack>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={12}>
            <DisplayFlagInfo />
          </Grid>
        </Grid>

        <Stack spacing={2} sx={{ mt: 4 }}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item xs="auto">
              <Typography variant="h5">Experiments:</Typography>
            </Grid>
            <Grid item xs="auto">
              {StateSelector()}
            </Grid>
          </Grid>

          {rulesLoaded ? <ShowRulesList /> : <Loader />}
        </Stack>
        {viewRulesData.filter((item: any) => item.type === "rollout").length >
        0 ? (
          <Stack spacing={2} sx={{ mt: 4 }}>
            <Typography variant="h5">Rollouts:</Typography>
            {rulesLoaded ? <ShowRolloutsList /> : <Loader />}
          </Stack>
        ) : (
          <></>
        )}
      </Stack>
    );
  };

  const ShowRolloutsList: React.FC<any> = () => {
    return (
      <Stack>
        {viewRulesData
          .filter((item: any) => item.type === "rollout")
          .map((item: any, key: any) => (
            <Paper
              onClick={(e) =>
                dashboardLink(
                  e,
                  navigate,
                  `/features/${flagId}/${item.id}?${window.location.search.replace(
                    "?",
                    ""
                  )}`
                )
              }
              key={key}
              className={`${item.state === 30 ? "rule-is-live" : ""} `}
              sx={{
                p: 1,
                pb: 0,
                mb: 2,
                "&:hover": { opacity: "0.6" },
                minHeight: 80,
                position: "relative",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: "-20px",
                  top: "30px",
                  width: "20px",
                  visibility:
                    flagData.team_id !== 1 || Number(selectRegion) !== 0
                      ? "visible"
                      : "hidden",
                }}
              >
                <Typography align="right" variant="caption">
                  {key + 1}
                </Typography>
              </div>
              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid
                  item
                  container
                  xs={9}
                  sm={8}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid container columnSpacing={1} sx={{ ml: 0 }}>
                    <Grid item xs={7}>
                      <Typography
                        variant="button"
                        display="block"
                        noWrap={true}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        noWrap={true}
                        sx={{
                          "&::after": { content: '""' },
                          whiteSpace: "pre",
                        }}
                      >
                        {item.description.length < 1
                          ? " "
                          : item.description.slice(0, 100)}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        display="inline"
                        fontSize="x-small"
                      >
                        Type:{" "}
                      </Typography>
                      <Typography
                        variant="body2"
                        display="inline"
                        fontSize="x-small"
                      >
                        {item.type === "mab"
                          ? "Multi-armed bandit"
                          : item.type === "rollout"
                            ? "Rollout"
                            : "A/B-Test"}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="button" display="block">
                        Audiences:
                      </Typography>
                      <Grid
                        container
                        columnSpacing={1}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        direction="row"
                        sx={{
                          position: "relative",
                          overflow: "hidden",
                          "& .MuiChip-root": {
                            fontSize: "0.6125rem",
                            height: 16,
                            lineHeight: "0.6125rem",
                          },
                        }}
                      >
                        {showAudiencesList(item.attributes)}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{ display: { xs: "none", md: "block" } }}
                    >
                      <Typography variant="button" display="block">
                        {getRegionName(item.region_key) ? "Region" : ""}
                      </Typography>
                      <Typography variant="caption" display="block">
                        {getRegionName(item.region_key)
                          ? getRegionName(item.region_key)
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xs
                  item
                  container
                  flexShrink={0}
                  minWidth={185}
                  justifyContent="flex-end"
                >
                  <Grid item xs="auto">
                    <ChangeState
                      ruleData={item}
                      currentState={item.state}
                      callback={changeStateCallback}
                      disabled={
                        item.archived === 1 ||
                        config.user.level < 25 ||
                        (config.user.region.length > 0 &&
                          config.user.region !== item.region_key)
                      }
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <MoreMenu item={item} />
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {item.schedule ? showSchedule(item.schedule) : <></>}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          ))}
      </Stack>
    );
  };

  const ShowRulesList: React.FC<any> = () => {
    return (
      <SortableList onSortEnd={handleOnSortEnd} style={{ marginTop: 0 }}>
        {viewRulesData
          .filter((item: any) => item.type !== "rollout" && item.state !== 50)
          .map((item: any, key: any) => (
            <SortableItem key={key}>
              <Paper
                onClick={(e) =>
                  dashboardLink(
                    e,
                    navigate,
                    `/features/${flagId}/${item.id}?${window.location.search.replace(
                      "?",
                      ""
                    )}`
                  )
                }
                key={key}
                className={`${item.state === 30 ? "rule-is-live" : ""} `}
                sx={{
                  p: 1,
                  pb: 0,
                  my: 2,
                  "&:hover": { opacity: "0.6" },
                  minHeight: 80,
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: "-20px",
                    top: "30px",
                    width: "20px",
                    visibility:
                      (flagData.team_id !== 1 || Number(selectRegion) !== 0) &&
                      selectedState < 0
                        ? "visible"
                        : "hidden",
                  }}
                >
                  <Typography align="right" variant="caption">
                    {key + 1}
                  </Typography>
                </div>
                <Grid
                  container
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <SortableKnob>
                    {dragEnabled(item.region_key) ? (
                      <DragSpace />
                    ) : (
                      <div style={{ margin: "12px" }}></div>
                    )}
                  </SortableKnob>
                  <Grid
                    item
                    container
                    xs={9}
                    sm={8}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid container columnSpacing={1} sx={{ ml: 0 }}>
                      <Grid item xs={7}>
                        <Typography
                          variant="button"
                          display="block"
                          noWrap={true}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          noWrap={true}
                          sx={{
                            "&::after": { content: '""' },
                            whiteSpace: "pre",
                          }}
                        >
                          {item.description.length < 1
                            ? " "
                            : item.description.slice(0, 100)}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          display="inline"
                          fontSize="x-small"
                        >
                          Type:{" "}
                        </Typography>
                        <Typography
                          variant="body2"
                          display="inline"
                          fontSize="x-small"
                        >
                          {item.type === "mab"
                            ? "Multi-armed bandit"
                            : "A/B-Test"}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="button" display="block">
                          Audiences:
                        </Typography>
                        <Grid
                          container
                          columnSpacing={1}
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          direction="row"
                          sx={{
                            position: "relative",
                            overflow: "hidden",
                            "& .MuiChip-root": {
                              fontSize: "0.6125rem",
                              height: 16,
                              lineHeight: "0.6125rem",
                            },
                          }}
                        >
                          {showAudiencesList(item.attributes)}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: { xs: "none", md: "block" } }}
                      >
                        <Typography variant="button" display="block">
                          {getRegionName(item.region_key) ? "Region" : ""}
                        </Typography>
                        <Typography variant="caption" display="block">
                          {getRegionName(item.region_key)
                            ? getRegionName(item.region_key)
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    xs
                    item
                    container
                    flexShrink={0}
                    minWidth={185}
                    justifyContent="flex-end"
                  >
                    <Grid item xs="auto">
                      <ChangeState
                        ruleData={item}
                        listView={true}
                        currentState={item.state}
                        callback={changeStateCallback}
                        hasClashes={item.clashes > 0}
                        disabled={
                          item.archived === 1 ||
                          config.user.level < 25 ||
                          (config.user.region.length > 0 &&
                            config.user.region !== item.region_key)
                        }
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <MoreMenu item={item} />
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {item.schedule ? showSchedule(item.schedule) : <></>}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </SortableItem>
          ))}
      </SortableList>
    );
  };

  return <ShowRulesData />;
};
export default ShowRules;
